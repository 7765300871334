<template>
  <div id="cardCaixasAbertos" v-if="fetchUser && fetchUser.db.caixasAbertos">
    <q-card-section class="q-pb-sm">
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            Caixas Abertos
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section class="q-pt-none">
      <q-markup-table dense flat separator="none">
        <tbody>
          <tr
            v-for="caixa in fetchUser.db.caixasAbertos"
            :key="caixa.id"
          >
            <td class="text-left">{{ new Date(caixa.dtmovimento) | formatDate }}</td>
            <td class="text-right">
              <router-link :to="`/caixas/${caixa.id}`" style="text-decoration: none; color: black">
                {{ caixa.caixa_nome }}
              </router-link>
            </td>
            <td class="text-right">{{ caixa.func_resp_nome }}</td>
            <td class="text-right">{{ caixa.total | currency }}</td>
          </tr>
          <tr v-if="fetchUser.db.caixasAbertos.length === 0">
            <td colspan="4">Não há nenhum caixa aberto!</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "card-caixas-abertos",
  methods: {
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "13",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser"]),
  },
};
</script>

<style lang="stylus" scoped>
  #cardCaixasAbertos
    height:100%;
    overflow-x: auto;
</style>
