<template>
  <div id="recorrencias">
    <q-card white bordered class="q-mb-md">
      <q-card-section @click="qrecorrencias = !qrecorrencias" class="row items-center">
        <div class="text-h6 cursor-pointer">
          <q-btn
            dense
            flat
            no-wrap
            v-ripple
            round
            icon="add"
            no-caps
            class="q-ml-sm q-mr-md bg-white text-blue-7"
            clickable
            size="11px"
            @click.stop="modalAdicionarEditar()"
            :loading="loading"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template> </q-btn
          >Assinaturas
        </div>
        <q-space />
        <q-btn
          flat
          no-wrap
          v-ripple
          no-caps
          class="q-mr-md"
          color="primary"
          clickable
          label="Métricas de SaaS"
          :to="`/metricas-saas?cadastro_id=${cadastroId}`"
          v-if="qrecorrencias"
        ></q-btn>
        <q-btn
          no-wrap
          v-ripple
          no-caps
          class="q-mr-md"
          color="primary"
          clickable
          label="Faturar"
          v-if="qrecorrencias"
          @click.stop="faturarCliente()"
        ></q-btn>
        <q-btn
          dense
          flat
          no-wrap
          v-ripple
          round
          color="white"
          text-color="black"
          no-caps
          :icon="qrecorrencias ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          style="margin-right: 18px"
          clickable
        ></q-btn>
      </q-card-section>
      <q-card-section v-if="qrecorrencias">
        <q-table
          title
          :data="recorrencias"
          :columns="columns"
          row-key="id"
          :separator="'horizontal'"
          :loading="buscando"
          no-data-label="Nenhum resultado encontrado."
          :pagination.sync="pagination"
        >
          <template v-slot:bottom>
            <div></div>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
              <q-td key="ativo" class="text-center">
                <q-icon
                  :name="props.row.ativo ? 'check' : 'close'"
                  size="24px"
                  :class="props.row.ativo ? 'text-positive' : 'text-negative'"
                ></q-icon>
                <div v-if="props.row.congelado">
                  <q-badge color="orange-6">
                    <span style="line-height: 1.5em;">
                      Congelada
                      <span v-if="props.row.congelado_dtfim">
                        <div>até {{ props.row.congelado_dtfim | formatDate }}</div>
                      </span>
                    </span>
                  </q-badge>
                </div>
                <div v-if="props.row.is_trial === 1">
                  <q-badge color="orange-9">
                    <span>
                      Trial
                    </span>
                  </q-badge>
                </div>
              </q-td>
              <q-td key="dependente_id" class="text-left" v-if="exibirCampoVinculo">
                {{ props.row.dependente ? props.row.dependente.nome : cadastroNome }}
                <span v-if="props.row.convenio"><br />{{ props.row.convenio.nome }}</span>
                {{ props.row.numeroconvenio ? props.row.numeroconvenio : '' }}
              </q-td>
              <q-td key="descricao" class="text-left">
                <span v-if="props.row.quantidade > 1">({{ props.row.quantidade }})</span>
                <span>
                  {{ props.row.descricao | truncate(80) }}
                  <q-tooltip v-if="props.row.descricao && props.row.descricao.length > 80">
                    {{ props.row.descricao }}
                  </q-tooltip>
                </span>
                <br />
                Intervalo: {{ getPeriodoDescricao(props.row.periodo) }}
                <span v-if="props.row.ultimo_faturamento">
                  - Faturado em {{ new Date(props.row.ultimo_faturamento) | formatDate }}
                  </span> <span v-if="props.row.dia_cobranca">Dia {{ props.row.dia_cobranca }}</span>
                <br v-if="props.row.dtini || props.row.dtfim" />
                <span class="text-caption text-grey" v-if="props.row.dtini"
                  >Início: {{ props.row.dtini | formatDate }}</span
                >
                <span class="text-caption text-grey" v-if="props.row.dtfim">
                  Fim: {{ props.row.dtfim | formatDate }}</span
                >
                <span v-if="!props.row.aprovadoem" class="text-negative"><br />Não aprovado</span>
              </q-td>
              <q-td key="valor" class="text-right">
                R$ {{ props.row.valor | currency }}
              </q-td>
              <q-td key="valor_ativacao" class="text-right">
                R$ {{ props.row.valor_ativacao | currency }}
              </q-td>
              <q-td key="total_contratacao" class="text-right">
                R$ {{ getTotalContratacao(props.row) | currency }}
              </q-td>
              <!-- <q-td key="valor_desconto" class="text-right">
                {{ getTipoDesconto(props.row) }} {{props.row.valor_desconto | currency}} {{ getTipoDesconto(props.row, true) }}
              </q-td> -->
              <q-td v-if="$q.platform.is.desktop" class="tdfora">
                <div class="divfora">
                  <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                    <q-btn
                      v-if="!props.row.aprovadoem"
                      @click="aprovarRecorrencia(props.row)"
                      :loading="props.row.id === aprovandoRecorrencia"
                      size="12px"
                      color="positive"
                      text-color="white"
                      icon="thumb_up_alt"
                    >
                      <q-tooltip>Aprovar</q-tooltip>
                    </q-btn>
                    <q-btn
                      size="12px"
                      color="secondary"
                      text-color="white"
                      :icon="props.row.congelado ? 'reply' : 'stop'"
                      :loading="props.row.id === aprovandoRecorrencia"
                      v-if="props.row.aprovadoem"
                      @click="congelarRecorrencia(props.row)"
                    >
                      <q-tooltip>{{ props.row.congelado ? 'Descongelar' : 'Congelar' }}</q-tooltip>
                    </q-btn>
                    <q-btn
                      size="12px"
                      icon="pending_actions"
                      class="bg-positive text-white"
                      @click="modalEditarTrial(props.row)"
                      v-if="utilizarTrial"
                    >
                      <q-tooltip>Período de Teste</q-tooltip>
                    </q-btn>
                    <q-btn
                      @click="abrirModalComissoes(props.row)"
                      size="12px"
                      icon="redeem"
                      color="accent"
                      v-if="utilizarComissoes"
                    >
                      <q-tooltip>Comissões</q-tooltip>
                    </q-btn>
                    <q-btn
                      @click="modalAdicionarEditar(props.row)"
                      size="12px"
                      color="secondary"
                      icon="edit"
                    >
                      <q-tooltip>Editar</q-tooltip>
                    </q-btn>
                    <q-btn
                      @click="excluir(props.row)"
                      class="bg-negative text-white"
                      :icon="props.row.ativo ? 'block' : 'delete_forever'"
                      size="12px"
                    >
                      <q-tooltip>{{ props.row.ativo ? 'Inativar' : 'Excluir' }}</q-tooltip>
                    </q-btn>
                  </div>
                </div>
              </q-td>
            </q-tr>
            <q-tr v-show="props.expand" :props="props">
              <q-td colspan="100%">
                <div class="q-py-sm q-gutter-sm">
                  <q-btn
                    v-if="!props.row.aprovadoem"
                    @click="aprovarRecorrencia(props.row)"
                    :loading="props.row.id === aprovandoRecorrencia"
                    size="12px"
                    color="positive"
                    text-color="white"
                    icon="thumb_up_alt"
                  >
                    <q-tooltip>Aprovar</q-tooltip>
                  </q-btn>
                  <q-btn
                    size="12px"
                    color="orange"
                    text-color="white"
                    :icon="props.row.congelado ? 'reply' : 'stop'"
                    :loading="props.row.id === aprovandoRecorrencia"
                    v-if="props.row.aprovadoem"
                    @click="congelarRecorrencia(props.row)"
                  >
                    <q-tooltip>{{ props.row.congelado ? 'Descongelar' : 'Congelar' }}</q-tooltip>
                  </q-btn>
                  <q-btn
                    size="12px"
                    icon="pending_actions"
                    class="bg-positive text-white"
                    @click="modalEditarTrial(props.row)"
                    v-if="utilizarTrial"
                  >
                    <q-tooltip>Período de Teste</q-tooltip>
                  </q-btn>
                  <q-btn
                    @click="abrirModalComissoes(props.row)"
                    size="12px"
                    icon="redeem"
                    class="bg-secondary text-white"
                    v-if="utilizarComissoes"
                  >
                    <q-tooltip>Comissões</q-tooltip>
                  </q-btn>
                  <q-btn
                    @click="modalAdicionarEditar(props.row)"
                    size="12px"
                    class="bg-primary text-white"
                    icon="edit"
                  >
                    <q-tooltip>Editar</q-tooltip>
                  </q-btn>
                  <q-btn
                    @click="excluir(props.row)"
                    class="bg-negative text-white"
                    :icon="props.row.ativo ? 'block' : 'delete_forever'"
                    size="12px"
                  >
                    <q-tooltip>{{ props.row.ativo ? 'Inativar' : 'Excluir' }}</q-tooltip>
                  </q-btn>
                </div>
              </q-td>
            </q-tr>
          </template>
          <template v-slot:bottom-row>
            <q-tr style="background-color: #F7F7F7">
              <q-td></q-td>
              <q-td></q-td>
              <q-td key="total_valor" class="text-right">
                <strong>R$ {{ totalRecorrencias.totalValor | currency }}</strong>
              </q-td>
              <q-td key="total_valor_ativacao" class="text-right">
                <strong>R$ {{ totalRecorrencias.totalValorAtivacao | currency }}</strong>
              </q-td>
              <q-td key="total_contratacao" class="text-right">
                <strong>R$ {{ totalRecorrencias.totalContratacao | currency }}</strong>
              </q-td>
              <q-td class="tdfora"></q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
    <q-dialog v-model="OpenDialogRecorrencias" persistent>
      <q-card
        :class="{
          scroll: true,
          'nova-recorrencia-card': true,
          open: opcoesAvancadas,
        }"
      >
        <q-card-section class="row items-center">
          <div class="text-h6">{{ isNew ? 'Nova ' : 'Editar ' }}Recorrência</div>
          <q-space />
          <q-btn icon="close" clickable flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-lg">
            <!-- OPÇÕES AVANÇADAS LADO ESQUERDO -->
            <div :class="opcoesAvancadas ? 'col-md-4' : 'col-12'">
              <div class="row q-col-gutter-xs">
                <div class="col-12">
                  <q-checkbox
                    v-model="recorrencia.ativo"
                    label="Ativo"
                    color="green"
                    @input="pulaCampo('periodo')"
                  />
                </div>

                <!-- <div class="col-12 q-pt-none">
                  <q-select
                    dense
                    v-model="plano"
                    :options="planos"
                    :option-label="value => value.nome"
                    label="Plano"
                    bottom-slots
                    clearable
                    :error="errors.plano ? true : false"
                  >
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.plano" :key="error + i">{{error}}</span>
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:after>
                      <q-btn
                        flat
                        round
                        icon="save_alt"
                        color="primary"
                        class="q-mt-md"
                        @click="importarPlano"
                        :loading="importandoPlano"
                      >
                        <q-tooltip>
                        Carregar informações do plano
                        </q-tooltip>
                      </q-btn>
                    </template>
                  </q-select>
                </div> -->

                <div class="col-6 q-pt-none">
                  <q-select
                    dense
                    emit-value
                    map-options
                    bottom-slots
                    v-model="recorrencia.periodo"
                    label="Período em meses"
                    ref="periodo"
                    :options="periodoMeses"
                    :error="errors.periodo ? true : false"
                    @keydown.enter.prevent="pulaCampo('dia_cobranca')"
                  >
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.periodo" :key="error + i">{{ error }}</span>
                    </template>
                  </q-select>
                </div>

                <div class="col-6 q-pt-none">
                  <q-input
                    dense
                    bottom-slots
                    mask="##"
                    ref="dia_cobranca"
                    label="Dia da cobrança"
                    :error="Boolean(errors.dia_cobranca)"
                    @keydown.enter.prevent="pulaCampo('convenio')"
                    v-model="recorrencia.dia_cobranca"
                  >
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.dia_cobranca" :key="error + i">
                        {{ error }}
                      </span>
                    </template>
                  </q-input>

                  <q-tooltip>
                    Se agrupado sobrescreve o dia de pagamento da recorrência
                  </q-tooltip>
                </div>

                <div class="col-12 q-pt-none">
                  <q-select
                    dense
                    clearable
                    v-model="recorrencia.convenio_id"
                    :options="convenios"
                    option-value="id"
                    option-label="nome"
                    map-options
                    label="Convênio"
                    ref="convenio"
                    bottom-slots
                    :error="errors.convenio ? true : false"
                    @input="pulaCampo('numeroConvenio')"
                    v-if="exibirCampoConvenio"
                  >
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.convenio" :key="error + i">{{
                        error
                      }}</span>
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-12 q-pt-none">
                  <q-input
                    dense
                    v-model="recorrencia.numeroconvenio"
                    label="Número Convênio"
                    ref="numeroConvenio"
                    bottom-slots
                    @keydown.enter.prevent="pulaCampo('dependente')"
                    v-if="exibirCampoConvenio"
                  />
                </div>

                <div class="col-12 q-pt-none">
                  <q-select
                    dense
                    clearable
                    v-model="recorrencia.dependente_id"
                    :options="dependentes"
                    label="Dependente"
                    option-value="id"
                    option-label="nome"
                    map-options
                    bottom-slots
                    ref="dependente"
                    :error="errors.dependente ? true : false"
                    @input="pulaCampo('descricao')"
                    v-if="exibirCampoVinculo"
                  >
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.dependente" :key="error + i">{{
                        error
                      }}</span>
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-12 q-pt-none">
                  <q-input
                    dense
                    v-model="recorrencia.descricao"
                    label="Descrição"
                    bottom-slots
                    ref="descricao"
                    @keydown.enter.prevent="pulaCampo('quantidade')"
                    :error="Boolean(errors.descricao)"
                  >
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.descricao" :key="error + i">{{
                        error
                      }}</span>
                    </template>
                  </q-input>
                </div>

                <div class="col-3 q-pt-none">
                  <q-input
                    dense
                    v-model="recorrencia.quantidade"
                    label="Quantidade"
                    bottom-slots
                    mask="######"
                    ref="quantidade"
                    :error="errors.quantidade ? true : false"
                    @keydown.enter.prevent="pulaCampo('valor')"
                  >
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.quantidade" :key="error + i">{{
                        error
                      }}</span>
                    </template>
                  </q-input>
                </div>

                <div class="col-9 q-pt-none" @keydown.enter.prevent="save()">
                  <q-field
                    dense
                    v-model="recorrencia.valor"
                    label="Valor"
                    bottom-slots
                    :error="errors.valor ? true : false"
                  >
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                        ref="valor"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.valor" :key="error + i">{{ error }}</span>
                    </template>
                  </q-field>
                </div>

                <div class="col-12" v-if="editarPlanodecontas">
                  <q-select
                    dense
                    use-input
                    clearable
                    bottom-slots
                    input-debounce="0"
                    option-value="id"
                    option-label="titulo"
                    label="Planos de contas"
                    @filter="filterPlanosdecontas"
                    :options="planosdecontasOptions"
                    v-model="recorrencia.planodeconta"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-12">
                  <q-select
                    dense
                    use-input
                    clearable
                    bottom-slots
                    option-value="id"
                    option-label="nome"
                    map-options
                    emit-value
                    label="Planos"
                    :options="planosOptions"
                    v-model="recorrencia.plano_id"
                    v-if="exibirCampoPlano"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">Sem Resultados</q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>

                <div class="col-12 flex justify-end">
                  <q-btn
                    flat
                    dense
                    no-caps
                    label="Opções Avançadas"
                    :icon-right="opcoesAvancadas ? 'keyboard_arrow_left' : 'keyboard_arrow_right'"
                    @click="toggleOpcoesAvancadas()"
                  />
                </div>

                <!-- component uploader start -->
                <upload-document-recorrencia v-if="opcoesAvancadas" :uploads="uploads" :recorrencia="recorrencia" :isNew="isNew"/>
                <!-- component uploader end -->
              </div>
            </div>

            <!-- OPÇÕES AVANÇADAS LADO DIREITO -->
            <div :class="opcoesAvancadas ? 'col-md-8' : 'col-12'" v-show="opcoesAvancadas">
              <div class="row q-col-gutter-xs">
                <div class="col-12">
                  <div class="row q-col-gutter-x-md">
                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        clearable
                        bottom-slots
                        label="Data de início"
                        :error="Boolean(errors.dtini)"
                        v-model="recorrencia.dtini"
                        @click="$refs.qDateIni.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateIni"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="recorrencia.dtini"
                                @input="() => pulaCampo('qDateFim')"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.dtini" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                      </q-input>
                    </div>
                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        clearable
                        bottom-slots
                        label="Data de fim"
                        :error="Boolean(errors.dtfim)"
                        v-model="recorrencia.dtfim"
                        @click="$refs.qDateFim.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateFim"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="recorrencia.dtfim"
                                @input="() => pulaCampo('qDateBloqueio')"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.dtfim" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                      </q-input>
                    </div>
                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        clearable
                        bottom-slots
                        label="Data de bloqueio"
                        :error="Boolean(errors.data_bloqueio)"
                        v-model="recorrencia.data_bloqueio"
                        @click="$refs.qDateBloqueio.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateBloqueio"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="recorrencia.data_bloqueio"
                                @input="() => pulaCampo('quantidadeItem')"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.data_bloqueio" :key="error + i">{{error}}</span>
                        </template>
                      </q-input>
                    </div>
                  </div>
                </div>

                <!-- ITEMS -->
                <div class="col-12">
                  <div class="row q-col-gutter-x-md">
                    <div class="col-12">
                      <p class="q-mb-none"><strong>Itens</strong></p>
                    </div>

                    <div class="col-12">
                      <q-select
                        dense
                        use-input
                        clearable
                        emit-value
                        map-options
                        bottom-slots
                        input-debounce="0"
                        option-value="id"
                        option-label="titulo"
                        label="Planos de contas"
                        :options="planosdecontasOptions"
                        @filter="filterPlanosdecontas"
                        @input="pulaCampo('quantidadeItem')"
                        v-model="item.planodeconta_id"
                      >
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey">Sem Resultados</q-item-section>
                          </q-item>
                        </template>
                      </q-select>
                    </div>

                    <div class="col-2">
                      <q-input
                        dense
                        v-model="item.quantidade"
                        label="Quantidade"
                        bottom-slots
                        mask="###"
                        :error="errorsItem.quantidade ? true : false"
                        ref="quantidadeItem"
                        @keydown.enter.prevent="pulaCampo('descricaoItem')"
                      >
                        <template v-slot:error>
                          <span v-for="(error, i) in errorsItem.quantidade" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                      </q-input>
                    </div>

                    <div class="col-5">
                      <q-input
                        dense
                        v-model="item.descricao"
                        label="Descrição"
                        bottom-slots
                        :error="errorsItem.descricao ? true : false"
                        ref="descricaoItem"
                        @keydown.enter.prevent="pulaCampo('valorItem')"
                      >
                        <template v-slot:error>
                          <span v-for="(error, i) in errorsItem.descricao" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                      </q-input>
                    </div>

                    <div class="col-5" @keydown.enter.prevent="addItem()">
                      <q-field
                        dense
                        v-model="item.valor"
                        label="Valor"
                        bottom-slots
                        :error="errorsItem.valor ? true : false"
                      >
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            ref="valorItem"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errorsItem.valor" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                        <template v-slot:after>
                          <q-btn
                            flat
                            round
                            icon="add"
                            class="align-self-center"
                            style="height: 42px"
                            color="primary"
                            @click="addItem"
                          />
                        </template>
                      </q-field>
                    </div>

                    <q-markup-table
                      flat
                      dense
                      class="q-my-md full-width"
                      separator="none"
                      v-if="recorrencia.cadastros_recorrencias_items"
                    >
                      <thead>
                        <tr>
                          <th>Descrição</th>
                          <th>Plano de contas</th>
                          <th>Valor</th>
                          <th style="width: 50px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in recorrencia.cadastros_recorrencias_items"
                          :key="item.funcionarioId"
                        >
                          <td>
                            ({{ item.quantidade }}) {{ item.descricao }}
                            <br v-if="item.dtini || item.dtfim" />
                            <span v-if="item.dtini">Início: {{ formatDate(item.dtini) }}</span>
                            <span v-if="item.dtfim"> Fim: {{ formatDate(item.dtfim) }}</span>
                          </td>
                          <td class="text-left">
                            <span v-if="item.planodeconta">{{ item.planodeconta.titulo }}</span>
                          </td>
                          <td class="text-right">R$ {{ item.valor | currency }}</td>
                          <td style="width: 50px">
                            <q-btn
                              flat
                              dense
                              size="12px"
                              color="red"
                              icon="delete"
                              @click="removeItem(index)"
                            />
                          </td>
                        </tr>
                        <tr v-if="recorrencia.cadastros_recorrencias_items.length === 0">
                          <td colspan="3" align="center">Nenhum item</td>
                        </tr>
                      </tbody>
                    </q-markup-table>
                  </div>
                </div>

                <!-- DESCONTOS -->
                <div class="col-12">
                  <div class="row q-col-gutter-x-md">
                    <div class="col-12">
                      <p class="q-mb-none"><strong>Descontos</strong></p>
                    </div>

                    <div @keydown.enter.prevent="pulaCampo('qDateFimDesconto')" class="col-6">
                      <q-field
                        dense
                        v-model="desconto.valor_desconto"
                        label="Desconto"
                        bottom-slots
                        :error="errorsDesconto.valor_desconto ? true : false"
                        style="width: 100%"
                      >
                        <template v-slot:before>
                          <q-btn
                            small
                            size="md"
                            style="width: 49px"
                            :label="desconto.tipo_desconto === '%' ? '%' : 'R$'"
                            v-model="desconto.tipo_desconto"
                            @click="toggleTipoDesconto()"
                          />
                        </template>
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="desconto.tipo_desconto === '%' ? percentFormat : moneyFormat"
                            v-show="floatingLabel"
                            ref="valorDesconto"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errorsDesconto.valor_desconto"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-field>
                    </div>

                    <div class="col-6">
                      <q-input
                        dense
                        bottom-slots
                        label="Fim do desconto"
                        :error="Boolean(errorsDesconto.dtfim)"
                        v-model="desconto.dtfim"
                        @click="$refs.qDateFimDesconto.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateFimDesconto"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="desconto.dtfim"
                                @input="() => addDesconto()"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errorsDesconto.dtfim" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                        <template v-slot:after>
                          <q-btn
                            flat
                            round
                            icon="add"
                            class="align-self-center"
                            style="height: 42px"
                            color="primary"
                            @click="addDesconto"
                          />
                        </template>
                      </q-input>
                    </div>

                    <q-markup-table
                      flat
                      dense
                      class="q-my-md full-width"
                      separator="none"
                      v-if="recorrencia.cadastros_recorrencias_descontos"
                    >
                      <tbody>
                        <tr
                          v-for="(desconto, index) in recorrencia.cadastros_recorrencias_descontos"
                          :key="desconto.funcionarioId"
                        >
                          <td>até {{ formatDate(desconto.dtfim) }}</td>
                          <td class="text-right">
                            {{ desconto.valor_desconto | currency | value(desconto.tipo_desconto) }}
                          </td>
                          <td style="width: 50px">
                            <q-btn
                              flat
                              dense
                              size="12px"
                              color="red"
                              icon="delete"
                              @click="removeDesconto(index)"
                            />
                          </td>
                        </tr>
                        <tr v-if="recorrencia.cadastros_recorrencias_descontos.length === 0">
                          <td colspan="3" align="center">Nenhum desconto</td>
                        </tr>
                      </tbody>
                    </q-markup-table>

                    <!-- <p @click.prevent="showDescontoAjuda = !showDescontoAjuda" class="text-grey cursor-pointer">
                      <q-icon :name="showDescontoAjuda ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" />
                      {{ showDescontoAjuda ? "Menos" : "Mais" }} informações
                    </p>

                    <div class="text-caption" v-if="showDescontoAjuda">
                      <p class="q-mb-xs">Os itens não afetam o valor da parcela, são apenas informativos</p>
                      <p class="q-mb-xs">Fórmula do desconto: (todos os descontos percentuais somados / 100 * recorrência)
                        + todos os descontos fixos somados</p>
                    </div> -->
                  </div>
                </div>

                <!-- DESCONTOS ASSINATURAS -->
                <div class="col-12">
                  <div class="row q-col-gutter-x-md">
                    <div class="col-12">
                      <p class="q-mb-none"><strong>Desconto no pagamento adiantado</strong></p>
                    </div>

                    <div class="col-6">
                      <q-field
                        dense
                        v-model="recorrencia.desconto"
                        label="Desconto"
                        bottom-slots
                        style="width: 100%"
                      >
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            ref="valorDesconto"
                          >
                          </money>
                        </template>
                      </q-field>
                    </div>

                    <div class="col-6">
                      <q-input
                        dense
                        v-model="recorrencia.desconto_dias_antecipacao"
                        label="Dias de Antecipação"
                        bottom-slots
                        mask="###"
                        ref="quantidadeDesconto"
                      >
                      </q-input>
                    </div>

                  </div>
                </div>

                <!-- PARCELAS -->
                <div class="col-12">
                  <div class="row q-col-gutter-x-md">
                    <div class="col-12">
                      <p class="q-mb-none"><strong>Parcelas</strong></p>
                    </div>

                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        label="Descrição"
                        ref="descricaoParcela"
                        v-model="parcela.descricao"
                        @keydown.enter.prevent="pulaCampo('valorParcela')"
                      />
                    </div>

                    <div
                      @keydown.enter.prevent="pulaCampo('qDateVencimentoParcela')"
                      class="col-12 col-md-4"
                    >
                      <q-field
                        dense
                        v-model="parcela.valor"
                        label="Valor"
                        bottom-slots
                        :error="errorsParcela.valor ? true : false"
                        style="width: 100%"
                      >
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            ref="valorParcela"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errorsParcela.valor" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                      </q-field>
                    </div>

                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        bottom-slots
                        label="Vencimento"
                        :error="Boolean(errorsParcela.vencimento)"
                        v-model="parcela.vencimento"
                        @click="$refs.qDateVencimentoParcela.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateVencimentoParcela"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="parcela.vencimento"
                                @input="() => addParcela()"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errorsParcela.vencimento" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                        <template v-slot:after>
                          <q-btn
                            flat
                            round
                            icon="add"
                            class="align-self-center"
                            style="height: 42px"
                            color="primary"
                            @click="addParcela"
                          />
                        </template>
                      </q-input>
                    </div>

                    <q-markup-table
                      flat
                      dense
                      class="q-my-md full-width"
                      separator="none"
                      v-if="recorrencia.cadastros_recorrencias_parcelas"
                    >
                      <tbody>
                        <tr
                          v-for="(parcela, index) in recorrencia.cadastros_recorrencias_parcelas"
                          :key="parcela.funcionarioId"
                        >
                          <td style="white-space: break-spaces">{{ parcela.descricao }}</td>
                          <td class="text-center">{{ formatDate(parcela.vencimento) }}</td>
                          <td class="text-right">{{ parcela.valor | currency }}</td>
                          <td style="width: 50px">
                            <q-btn
                              flat
                              dense
                              size="12px"
                              color="red"
                              icon="delete"
                              @click="removeParcela(index)"
                            />
                          </td>
                        </tr>
                        <tr v-if="recorrencia.cadastros_recorrencias_parcelas.length === 0">
                          <td colspan="4" align="center">Nenhuma parcela</td>
                        </tr>
                      </tbody>
                    </q-markup-table>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row q-col-gutter-md">

                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        clearable
                        bottom-slots
                        label="Primeiro Pagamento"
                        :error="Boolean(errors.data_primeiro_pagamento)"
                        v-model="recorrencia.data_primeiro_pagamento"
                        @click="$refs.qDatePrimeiroPagamento.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDatePrimeiroPagamento"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="recorrencia.data_primeiro_pagamento"
                                @input="() => pulaCampo('qDateUltimoFaturamentoEm')"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.data_primeiro_pagamento" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                      </q-input>
                    </div>

                    <div class="col-12 col-md-4">
                      <q-input
                        dense
                        clearable
                        bottom-slots
                        label="Último faturamento"
                        :error="Boolean(errors.ultimo_faturamento)"
                        v-model="recorrencia.ultimo_faturamento"
                        @click="$refs.qDateUltimoFaturamentoEm.show()"
                      >
                        <template v-slot:append>
                          <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy
                              ref="qDateUltimoFaturamentoEm"
                              transition-show="scale"
                              transition-hide="scale"
                            >
                              <q-date
                                v-model="recorrencia.ultimo_faturamento"
                                @input="() => pulaCampo('valor_ativacao')"
                                mask="DD/MM/YYYY"
                                :locale="langDate"
                                minimal
                                today-btn
                              />
                            </q-popup-proxy>
                          </q-icon>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.ultimo_faturamento" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                      </q-input>
                    </div>

                    <div class="col-12 col-md-4" @keydown.enter.prevent="save()">
                      <q-field
                        dense
                        v-model="recorrencia.valor_ativacao"
                        label="Valor de ativação"
                        bottom-slots
                        :error="errors.valor ? true : false"
                      >
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                            ref="valor_ativacao"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span v-for="(error, i) in errors.valor" :key="error + i">{{
                            error
                          }}</span>
                        </template>
                      </q-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            no-caps
            v-ripple
            clickable
            type="submit"
            label="Salvar"
            color="positive"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="saveRecorrencia"
            @click="save()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="OpenDialogAddItem" persistent @keydown.enter.prevent="confirmAddItem()">
      <q-card class="scroll" style="width: 90%; max-width: 400px">
        <q-card-section class="row items-center">
          <div class="text-h6">Adicionar Item</div>
          <q-space />
          <q-btn icon="close" clickable flat round dense v-close-popup @click="closeModalItem()" />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-x-md">
            <div class="col-6">
              <q-input
                dense
                bottom-slots
                label="Início"
                :error="Boolean(errorsItem.dtini)"
                v-model="item.dtini"
                @click="$refs.qDateInicioItem.show()"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateInicioItem"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="item.dtini"
                        @input="() => pulaCampo('qDateFimItem')"
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsItem.dtini" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-6">
              <q-input
                dense
                bottom-slots
                label="Fim"
                :error="Boolean(errorsItem.dtfim)"
                v-model="item.dtfim"
                @click="$refs.qDateFimItem.show()"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateFimItem"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="item.dtfim"
                        @input="() => confirmAddItem()"
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsItem.dtfim" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            no-caps
            v-ripple
            clickable
            label="Adicionar"
            color="positive"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
            @click="confirmAddItem()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="OpenDialogCongelarRecorrencia" persistent>
      <q-card class="scroll" style="width: 90%; max-width: 400px">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">{{ recorrenciaSelecionada.descricao }}</div>
          <q-space />
          <q-btn icon="close" clickable flat round dense v-close-popup @click="closeModalItem()" />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-x-md">
            <div class="col-12">Tem certeza que deseja congelar a recorrência?</div>

            <div class="col-12">
              <q-input
                dense
                clearable
                bottom-slots
                label="Fim do congelamento"
                :error="Boolean(errors.congelado_dtfim)"
                v-model="recorrenciaSelecionada.congelado_dtfim"
                @click="$refs.qDateCongelamentoDtfim.show()"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateCongelamentoDtfim"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        minimal
                        today-btn
                        mask="DD/MM/YYYY"
                        v-model="item.congelado_dtfim"
                        @input="value => handleCongeladoDtfim(value)"
                        :locale="langDate"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsItem.congelado_dtfim" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-input>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            no-caps
            v-ripple
            clickable
            label="Salvar"
            color="positive"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="loadingCongelar"
            @click="congelarRecorrenciaRequest(recorrenciaSelecionada)"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="OpenDialogTrial" persistent>
      <q-card class="scroll" style="width: 90%; max-width: 400px">
        <q-card-section class="row items-center">
          <div class="text-h6">Período de teste</div>
          <q-space />
          <q-btn flat round dense clickable v-close-popup icon="close" @click="closeModalTrial()" />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-x-md">
            <div class="col-12">
              <q-select
                dense
                emit-value
                map-options
                bottom-slots
                label="Período de teste"
                :options="optionsTrial"
                v-model="recorrencia.is_trial"
              >
                <template v-slot:error>
                  <span v-for="(error, i) in errors.is_trial" :key="error + i">{{ error }}</span>
                </template>
              </q-select>
            </div>

            <div class="col-6">
              <q-input
                dense
                clearable
                bottom-slots
                label="Início"
                :error="Boolean(errors.dtini_trial)"
                @click="$refs.qDateInicioTrial.show()"
                v-model="recorrencia.dtini_trial"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateInicioTrial"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        minimal
                        today-btn
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        @input="() => pulaCampo('qDateFimTrial')"
                        v-model="recorrencia.dtini_trial"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.dtini_trial" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-6">
              <q-input
                dense
                clearable
                bottom-slots
                label="Fim"
                :error="Boolean(errors.dtfim_trial)"
                @click="$refs.qDateFimTrial.show()"
                v-model="recorrencia.dtfim_trial"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateFimTrial"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        minimal
                        today-btn
                        @input="() => $refs.qDateFimTrial.hide()"
                        :locale="langDate"
                        mask="DD/MM/YYYY"
                        v-model="recorrencia.dtfim_trial"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.dtfim_trial" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-12">
              <q-input
                dense
                clearable
                bottom-slots
                label="Data de conversão/perda"
                :error="Boolean(errors.data_trial)"
                @click="$refs.qDataTrial.show()"
                v-model="recorrencia.data_trial"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="qDataTrial" transition-show="scale" transition-hide="scale">
                      <q-date
                        minimal
                        today-btn
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        @input="() => $refs.qDataTrial.hide()"
                        v-model="recorrencia.data_trial"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errors.data_trial" :key="error + i">{{ error }}</span>
                </template>
              </q-input>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            no-caps
            v-ripple
            clickable
            label="Salvar"
            color="positive"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="saveRecorrencia"
            @click="save()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="OpenDialogComissoes" persistent>
      <q-card class="scroll" style="width: 100%; max-width: 800px">
        <q-card-section class="row items-center">
          <div class="text-h6">
            Comissões: {{ recorrencia.descricao }} R$ {{ recorrencia.valor | currency }}
          </div>
          <q-space />
          <q-btn
            flat
            round
            dense
            clickable
            v-close-popup
            icon="close"
            @click="closeModalComissoes()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-x-md">

          <q-form
            @submit.prevent="addComissao()"
            class="row q-col-gutter-sm"
          >

            <div class="col-12 col-md-6">
              <q-select
                dense
                use-input
                bottom-slots
                label="Cadastro"
                option-value="id"
                option-label="nome"
                input-debounce="200"
                ref="comissoesCadastro"
                :options="cadastros"
                :error="Boolean(errorsComissoes.cadastro_id)"
                @input="pulaCampo('comissoesPlanodeconta')"
                @filter="(val, update) => buscarCadastro(val, update)"
                v-model="comissao.cadastro"
                :rules="[val => !!val || 'Campo Obrigatório']"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem resultados</q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsComissoes.cadastro_id" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-select>
            </div>

            <div class="col-12 col-md-6">
              <q-select
                dense
                use-input
                clearable
                bottom-slots
                option-value="id"
                input-debounce="0"
                option-label="titulo"
                label="Planos de contas"
                ref="comissoesPlanodeconta"
                :options="planosdecontasOptions"
                :error="Boolean(errorsComissoes.planodeconta_id)"
                @filter="filterPlanosdecontasComissoes"
                @input="pulaCampo('comissoesQuando')"
                v-model="comissao.planodeconta"
                :rules="[val => !!val || 'Campo Obrigatório']"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados</q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsComissoes.planodeconta_id" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-select>
            </div>

            <div class="col-12 col-md-6">
              <q-select
                dense
                emit-value
                map-options
                bottom-slots
                ref="comissoesQuando"
                :options="comissaoQuandoOptions"
                :error="Boolean(errorsComissoes.quando)"
                :label="`Gerar n${comissao.quando === 'liquidacao' ? 'a' : 'o'}`"
                @input="pulaCampo('comissoesItemQuando')"
                v-model="comissao.quando"
                :rules="[val => !!val || 'Campo Obrigatório']"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados</q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsComissoes.quando" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-select>
            </div>

            <div class="col-12 col-md-6">
              <q-select
                dense
                emit-value
                map-options
                bottom-slots
                label="da"
                ref="comissoesItemQuando"
                :options="comissaoItemQuandoOptions"
                :error="Boolean(errorsComissoes.item_quando)"
                @input="pulaCampo('comissoesOrigemValor')"
                v-model="comissao.item_quando"
                :rules="[val => !!val || 'Campo Obrigatório']"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados</q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsComissoes.item_quando" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-select>
            </div>

            <div class="col-12 col-md-6">
              <q-select
                dense
                emit-value
                map-options
                bottom-slots
                label="Origem do valor"
                ref="comissoesOrigemValor"
                :options="comissaoOrigemValorOptions"
                :error="Boolean(errorsComissoes.origem_valor)"
                @input="pulaCampo('comissoesValor')"
                v-model="comissao.origem_valor"
                :rules="[val => !!val || 'Campo Obrigatório']"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">Sem Resultados</q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsComissoes.origem_valor" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-select>
            </div>

            <div class="col-12 col-md-6" @keydown.enter.prevent="pulaCampo('qDateIniComissao')">
              <q-field
                dense
                bottom-slots
                label="Valor"
                :error="Boolean(errorsComissoes.valor)"
                v-model="comissao.valor"
                :rules="[val => !!val || 'Campo Obrigatório']"
              >
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <money
                    ref="comissoesValor"
                    class="q-field__native text-right"
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    v-bind="
                      ['recorrencia', 'parcela'].includes(comissao.origem_valor)
                        ? percentFormat
                        : moneyFormat
                    "
                    v-show="floatingLabel"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsComissoes.valor" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-field>
            </div>

            <div class="col-12 col-md-4">
              <q-input
                dense
                bottom-slots
                label="Data de início"
                :error="Boolean(errorsComissoes.dtini)"
                @click="$refs.qDateIniComissao.show()"
                v-model="comissao.dtini"
                :disable="this.comissao.item_quando === 'parcela'"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateIniComissao"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        minimal
                        today-btn
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        @input="
                          () => {
                            handleDtiniDtfimComissao();
                            pulaCampo('qDateFimComissao');
                          }
                        "
                        v-model="comissao.dtini"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsComissoes.dtini" :key="error + i">{{
                    error
                  }}</span>
                </template>
              </q-input>
            </div>

            <div class="col-12 col-md-3">
              <q-input
                dense
                bottom-slots
                label="Duração em meses"
                ref="comissoesQuantidadeMeses"
                v-model="quantidadeMesesComissao"
                @keydown.enter.prevent="pulaCampo('qDateFimComissao')"
                @blur="handleQuantidadeMesesComissao()"
                :disable="this.comissao.item_quando === 'parcela'"
              />
            </div>

            <div class="col-12 col-md-4">
              <q-input
                dense
                clearable
                bottom-slots
                label="Data de fim"
                :error="Boolean(errorsComissoes.dtfim)"
                @click="$refs.qDateFimComissao.show()"
                v-model="comissao.dtfim"
                :disable="this.comissao.item_quando === 'parcela'"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateFimComissao"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        minimal
                        today-btn
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        @input="
                          () => {
                            handleDtiniDtfimComissao();
                            $refs.qDateFimComissao.hide();
                          }
                        "
                        v-model="comissao.dtfim"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span v-for="(error, i) in errorsComissoes.dtfim" :key="error + i">{{
                    error
                  }}</span>
                </template>
                </q-input>
            </div>

             <div class="col-12 col-md-1">
                  <q-btn
                    flat
                    round
                    icon="add"
                    type="submit"
                    color="primary"
                    style="height: 42px"
                    class="align-self-center"
                    :loading="loadingSalvarComissao"
                  />
              </div>
            </q-form>

            <div class="col-12">
              <q-markup-table
                flat
                dense
                class="q-my-md full-width"
                separator="none"
                v-if="recorrencia.cadastros_recorrencias_comissoes"
              >
                <thead>
                  <tr>
                    <th>Informações</th>
                    <th>Geração</th>
                    <th>Valor</th>
                    <th style="width: 50px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="comissao in recorrencia.cadastros_recorrencias_comissoes"
                    :key="comissao.funcionarioId"
                  >
                    <td>
                      {{ comissao.cadastro.nome }}
                      <br v-if="comissao.planodeconta" />
                      <span v-if="comissao.planodeconta">{{ comissao.planodeconta.titulo }}</span>
                    </td>
                    <td class="text-left">
                      {{ comissao.quando === 'liquidacao' ? 'Na liquidação' : 'No faturamento' }}
                      da {{ comissao.item_quando === 'recorrencia' ? 'recorrência' : 'parcela' }}
                      <br />
                      <span>Início: {{ formatDate(comissao.dtini) }}</span>
                      <span v-if="comissao.dtfim"> Fim: {{ formatDate(comissao.dtfim) }}</span>
                    </td>
                    <td class="text-right">
                      <span v-if="comissao.origem_valor === 'fixo'">R$</span>
                      {{ comissao.valor | currency }}
                      <span v-if="comissao.origem_valor !== 'fixo'">%</span>
                      <br v-if="comissao.origem_valor !== 'fixo'" />
                      <span v-if="comissao.origem_valor !== 'fixo'"
                        >da
                        {{
                          comissao.origem_valor === 'recorrencia' ? 'recorrência' : 'parcela'
                        }}</span
                      >
                    </td>
                    <td style="width: 50px">
                      <q-btn
                        flat
                        dense
                        size="12px"
                        color="red"
                        icon="delete"
                        :loading="comissoesSalvando.includes(comissao.id)"
                        @click="removeComissao(comissao.id)"
                      />
                    </td>
                  </tr>
                  <tr v-if="recorrencia.cadastros_recorrencias_comissoes.length === 0">
                    <td colspan="3" align="center">Nenhuma comissão</td>
                  </tr>
                </tbody>
              </q-markup-table>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <modal-faturar />
  </div>
</template>

<script>
import axios from '@/axios-auth';
import { Loading } from 'quasar';
import { Money } from 'v-money';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { bus } from '@/main';

export default {
  name: 'recorrencias',
  components: { Money },
  props: ['cadastroNome'],
  data() {
    return {
      OpenDialogRecorrencias: false,
      OpenDialogAddItem: false,
      OpenDialogTrial: false,
      qrecorrencias: false,
      pagination: {
        sortBy: 'id',
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
      buscando: false,
      recorrencias: [],
      totalRecorrencias: {},
      periodos: [],
      convenios: [],
      dependentes: [],
      colunas: [],
      isNew: true,
      errors: [],
      recorrencia: {
        ativo: true,
        congelado: false,
        id: null,
        periodo: 1,
        convenio_id: null,
        numeroconvenio: '',
        dependente_id: null,
        descricao: '',
        valor: '0',
        valor_ativacao: 0.0,
        dia_cobranca: null,
        taxa_adesao: 0.0,
        quantidade: 1,
      },
      loading: false,
      saveRecorrencia: false,
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      percentFormat: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: ' %',
        precision: 2,
        masked: false,
      },
      plano: null,
      // planos: [],
      opcoesAvancadas: false,
      errorsDesconto: [],
      errorsItem: [],
      importandoPlano: false,
      item: {
        descricao: '',
        quantidade: 1,
        valor: 0,
        dtini: null,
        dtfim: null,
        planodeconta_id: null,
      },
      desconto: {
        dtfim: null,
        valor_desconto: 0,
        tipo_desconto: '$',
      },
      parcela: {
        descricao: '',
        valor: 0,
        vencimento: null,
      },
      errorsParcela: [],
      showDescontoAjuda: false,
      aprovandoRecorrencia: null,
      planosdecontas: [],
      planosdecontasComissoes: [],
      planosdecontasOptions: [],
      planosOptions: [],
      opcoes: {},
      optionsTrial: [
        {
          label: 'Sem período de teste',
          value: 0,
        },
        {
          label: 'Em período de teste',
          value: 1,
        },
        {
          label: 'Convertido',
          value: 2,
        },
        {
          label: 'Perdido',
          value: 3,
        },
      ],
      OpenDialogCongelarRecorrencia: false,
      loadingCongelar: false,
      recorrenciaSelecionada: {},
      OpenDialogComissoes: false,
      cadastros: [],
      errorsComissoes: {},
      comissao: {
        cadastro_id: null,
        cadastro: null,
        planodeconta_id: null,
        planodeconta: null,
        dtini: moment().format('DD/MM/YYYY'),
        dtfim: null,
        origem_valor: null,
        valor: 0,
        quando: null,
        item_quando: null,
      },
      comissaoQuandoOptions: [
        {
          label: 'Faturamento',
          value: 'faturamento',
        },
        {
          label: 'Liquidação',
          value: 'liquidacao',
        },
      ],
      comissaoItemQuandoOptions: [
        {
          label: 'Recorrência',
          value: 'recorrencia',
        },
        {
          label: 'Parcela',
          value: 'parcela',
        },
        {
          label: 'Ambos',
          value: 'ambos',
        },
      ],
      loadingSalvarComissao: false,
      comissoesSalvando: [],
      quantidadeMesesComissao: null,
      periodoMeses: [
        {
          label: 'Mensal',
          value: 1,
        },
        {
          label: 'Bimestral',
          value: 2,
        },
        {
          label: 'Trimestral',
          value: 3,
        },
        {
          label: 'Quadrimestral',
          value: 4,
        },
        {
          label: 'Semestral',
          value: 6,
        },
        {
          label: 'Anual',
          value: 12,
        }
      ],
      selectedFile: [],
      uploads: []
    };
  },
  methods: {
    closeModal() {
      this.OpenDialogRecorrencias = false;
      this.selectedFile = [];
      this.uploads = [];
      this.plano = null;
      this.opcoesAvancadas = false;
      this.clearDesconto();
    },
    closeModalItem() {
      this.OpenDialogAddItem = false;
    },
    clearDesconto() {
      this.desconto = {
        dtfim: null,
        valor: 0,
        tipo_desconto: '$',
      };
    },
    clearParcela() {
      this.parcela = {
        descricao: '',
        valor: 0,
        vencimento: null,
      };
    },
    clearItem() {
      this.item = {
        descricao: '',
        quantidade: 1,
        valor: 0,
        dtini: null,
        dtfim: null,
        planodeconta_id: null,
      };
    },
    toggleOpcoesAvancadas() {
      this.opcoesAvancadas = !this.opcoesAvancadas;
    },
    toggleTipoDesconto() {
      this.desconto.tipo_desconto = this.desconto.tipo_desconto === '%' ? '$' : '%';

      this.pulaCampo('valorDesconto');
    },
    pulaCampo(campo, segundos = null) {
      const focar = () => {
        if (!this.$refs[campo]) {
          return;
        }

        if (this.$refs[campo].show) {
          return this.$refs[campo].show();
        }

        if (this.$refs[campo].$el.classList.contains('v-money')) {
          return this.$refs[campo].$el.focus();
        }

        return this.$refs[campo].focus();
      };

      if (segundos) {
        return setTimeout(() => focar(), segundos);
      }

      focar();
    },
    listarRecorrencias() {
      if (this.buscando) {
        return;
      }
      this.buscando = true;
      axios
        .get('/cadastros/recorrencias/' + this.cadastroId)
        .then(res => {
          if (res.data.success) {
            this.recorrencias = res.data.data.recorrencias;
            this.opcoes = res.data.data.opcoes;
            this.totalRecorrencias = {
              totalValor: res.data.data.total_valor || 0,
              totalValorAtivacao: res.data.data.total_ativacao || 0,
              totalContratacao: res.data.data.total_contratacao || 0,
            };
            this.buscando = false;
          }
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.buscando = false;
        });
    },
    excluir(recorrencia) {
      let acao = 'excluir';
      let acaoAlt = 'exclusão';
      let mensagemSucesso = 'removida';
      let url = `/cadastros/delete-recorrencia/${recorrencia.id}`;
      let data = {};

      if (recorrencia.ativo) {
        acao = 'inativar';
        acaoAlt = 'inativação';
        mensagemSucesso = 'inativada';
        url = `/cadastros/edit-recorrencia/${recorrencia.cadastro_id}/${recorrencia.id}`;
      }

      this.$q
        .dialog({
          title: `Tem certeza que deseja ${acao} a recorrência?`,
          message: `Descreva o motivo da ${acaoAlt} `,
          cancel: 'Cancelar',
          persistent: true,
          prompt: {
            model: '',
            isValid: val => val.length > 2,
          },
        })
        .onOk(() => {
          Loading.show();
        })
        .onOk((motivo) => {
          data = {
            ativo: false,
            descricao: recorrencia.descricao,
            periodo: recorrencia.periodo,
            valor: recorrencia.valor,
            motivo: motivo
          };
          axios
            .post(url, data)
            .then(res => {
              if (res.error && res.error.response.status === 403) {
                this.notificarSemPermissao(res.error.response.data.message);
                return;
              }

              if (res.data.success) {
                this.$q.dialog({
                  title: `Recorrencia ${mensagemSucesso}!`,
                  class: 'bg-positive text-white',
                  ok: {
                    'text-color': 'green',
                    color: 'white',
                  },
                });
                this.listarRecorrencias();
              } else {
                this.$q.dialog({
                  title: res.data.message,
                  class: 'bg-warning text-white',
                  ok: {
                    'text-color': 'orange',
                    color: 'white',
                  },
                });
              }
            })
            .catch(() => {
              this.$q.dialog({
                title: 'Não foi possível realizar a ação, ou você não possui permissão isto!',
                class: 'bg-negative text-white',
                ok: {
                  'text-color': 'red',
                  color: 'white',
                },
              });
            });
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    congelarRecorrencia(recorrencia) {
      if (!recorrencia.congelado) {
        recorrencia = JSON.parse(JSON.stringify(recorrencia));

        if (!recorrencia.congelado_dtfim) {
          recorrencia.congelado_dtfim = '';
        }

        this.recorrenciaSelecionada = recorrencia;
        this.OpenDialogCongelarRecorrencia = true;
        return;
      }

      this.$q
        .dialog({
          title: recorrencia.descricao,
          message: 'Tem certeza que deseja descongelar a recorrência?',
          cancel: 'Cancelar',
          persistent: true,
        })
        .onOk(() => {
          Loading.show();
        })
        .onOk(() => {
          this.congelarRecorrenciaRequest(recorrencia);
        })
        .onCancel(() => {
          Loading.hide();
        })
        .onDismiss(() => {
          Loading.hide();
        });
    },
    congelarRecorrenciaRequest(recorrencia) {
      let mensagemSucesso = 'congelada';
      let data = {
        ativo: recorrencia.ativo,
        descricao: recorrencia.descricao,
        periodo: recorrencia.periodo,
        valor: recorrencia.valor,
        congelado: !recorrencia.congelado
      };

      if (recorrencia.congelado) {
        mensagemSucesso = 'descongelada';
      } else {
        data.congelado_dtfim = recorrencia.congelado_dtfim;
      }

      this.loadingCongelar = true;

      axios
        .post(`/cadastros/congelar-recorrencia/${recorrencia.id}`, data)
        .then(res => {
          this.OpenDialogCongelarRecorrencia = false;
          this.loadingCongelar = false;

          if (res.error && res.error.response.status === 403) {
            this.notificarSemPermissao(res.error.response.data.message);
            return;
          }

          if (res.data.success) {
            this.$q.dialog({
              title: `Recorrencia ${mensagemSucesso}!`,
              class: 'bg-positive text-white',
              ok: {
                'text-color': 'green',
                color: 'white',
              },
            });
            this.listarRecorrencias();
          } else {
            this.$q.dialog({
              title: res.data.message,
              class: 'bg-warning text-white',
              ok: {
                'text-color': 'orange',
                color: 'white',
              },
            });
          }
        })
        .catch(() => {
          this.OpenDialogCongelarRecorrencia = false;
          this.loadingCongelar = false;

          this.$q.dialog({
            title: 'Não foi possível realizar a ação, ou você não possui permissão isto!',
            class: 'bg-negative text-white',
            ok: {
              'text-color': 'red',
              color: 'white',
            },
          });
        });
    },
    tratarRecorrencia(recorrencia) {
      recorrencia.valor = recorrencia.valor.toFixed(2);

      if (recorrencia.dtini) {
        recorrencia.dtini = moment(recorrencia.dtini).format('DD/MM/YYYY');
      }
      if (recorrencia.dtfim) {
        recorrencia.dtfim = moment(recorrencia.dtfim).format('DD/MM/YYYY');
      }
      if (recorrencia.data_bloqueio) {
        recorrencia.data_bloqueio = moment(recorrencia.data_bloqueio).format('DD/MM/YYYY');
      }
      if (recorrencia.ultimo_faturamento) {
        recorrencia.ultimo_faturamento = moment(recorrencia.ultimo_faturamento).format(
          'DD/MM/YYYY'
        );
      }
      if (recorrencia.data_primeiro_pagamento) {
        recorrencia.data_primeiro_pagamento = moment(recorrencia.data_primeiro_pagamento).format(
          'DD/MM/YYYY'
        );
      }
      if (recorrencia.dtini_trial) {
        recorrencia.dtini_trial = moment(recorrencia.dtini_trial).format('DD/MM/YYYY');
      }
      if (recorrencia.dtfim_trial) {
        recorrencia.dtfim_trial = moment(recorrencia.dtfim_trial).format('DD/MM/YYYY');
      }

      return recorrencia;
    },
    modalAdicionarEditar(recorrencia) {
      let url = '/cadastros/add-recorrencia/' + this.cadastroId;

      this.loading = true;
      this.errors = [];
      this.errorsDesconto = [];

      if (recorrencia) {
        this.isNew = false;
        url = `/cadastros/edit-recorrencia/${this.cadastroId}/${recorrencia.id}`;
        this.recorrencia = recorrencia;
      } else {
        this.recorrencia = this.newRecorrencia();
        this.isNew = true;
      }
      axios
        .get(url)
        .then(res => {
          this.loading = false;

          if (res.error && res.error.response.status === 403) {
            this.notificarSemPermissao(res.error.response.data.message);
            return;
          }

          this.convenios = res.data.data.convenios;
          this.dependentes = res.data.data.dependentes;
          // this.planos = res.data.data.planos;
          this.OpenDialogRecorrencias = true;

          if (this.isNew) {
            return;
          }

          this.recorrencia = this.tratarRecorrencia(res.data.data.recorrencia);

          // if (this.recorrencia.plano_id) {
          //   this.plano = this.planos.find(plano => plano.id === this.recorrencia.plano_id);
          // }
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.loading = false;
        });
    },
    abrirModalComissoes(recorrencia) {
      this.loading = true;
      this.clearComissao();

      axios
        .get(`/cadastros/edit-recorrencia/${this.cadastroId}/${recorrencia.id}`)
        .then(res => {
          this.loading = false;

          if (res.error && res.error.response.status === 403) {
            this.notificarSemPermissao(res.error.response.data.message);
            return;
          }

          this.loadPlanosdecontasComissoes();

          this.recorrencia = this.tratarRecorrencia(res.data.data.recorrencia);

          this.OpenDialogComissoes = true;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.loading = false;
        });
    },
    clearComissao() {
      this.errorsComissoes = {};
      this.quantidadeMesesComissao = null;
      this.comissao = {
        cadastro_id: null,
        cadastro: null,
        planodeconta_id: null,
        planodeconta: null,
        dtini: moment().format('DD/MM/YYYY'),
        dtfim: null,
        origem_valor: null,
        valor: 0,
        quando: null,
        item_quando: null,
      };
    },
    modalEditarTrial(recorrencia) {
      let url = `/cadastros/edit-recorrencia/${this.cadastroId}/${recorrencia.id}`;

      this.loading = true;
      this.isNew = false;
      this.errors = [];
      this.errorsDesconto = [];

      axios
        .get(url)
        .then(res => {
          this.loading = false;

          if (res.error && res.error.response.status === 403) {
            this.notificarSemPermissao(res.error.response.data.message);
            return;
          }

          this.OpenDialogTrial = true;

          this.recorrencia = this.tratarRecorrencia(res.data.data.recorrencia);
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.loading = false;
        });
    },
    save() {
      this.saveRecorrencia = true;
      let recorrencia = JSON.parse(JSON.stringify(this.recorrencia));
      let action = this.getAction();
      const body = {
        convenio_id: !recorrencia.convenio_id ? null : recorrencia.convenio_id.id,
        dependente_id: !recorrencia.dependente_id ? null : recorrencia.dependente_id.id,
        planodeconta_id: !recorrencia.planodeconta ? null : recorrencia.planodeconta.id,
        descricao: recorrencia.descricao,
        numeroconvenio: recorrencia.numeroconvenio,
        periodo: recorrencia.periodo,
        plano_id: recorrencia.plano_id,
        valor: recorrencia.valor,
        valor_ativacao: recorrencia.valor_ativacao,
        ativo: recorrencia.ativo,
        dtini: recorrencia.dtini,
        dtfim: recorrencia.dtfim,
        data_bloqueio: recorrencia.data_bloqueio,
        quantidade: recorrencia.quantidade,
        ultimo_faturamento: recorrencia.ultimo_faturamento,
        data_primeiro_pagamento: recorrencia.data_primeiro_pagamento,
        is_trial: recorrencia.is_trial,
        dtini_trial: recorrencia.dtini_trial,
        dtfim_trial: recorrencia.dtfim_trial,
        data_trial: recorrencia.data_trial,
        dia_cobranca: recorrencia.dia_cobranca,
        cadastros_recorrencias_descontos: recorrencia.cadastros_recorrencias_descontos,
        cadastros_recorrencias_items: recorrencia.cadastros_recorrencias_items,
        cadastros_recorrencias_parcelas: recorrencia.cadastros_recorrencias_parcelas,
        cadastros_recorrencias_comissoes: recorrencia.cadastros_recorrencias_comissoes,
        desconto: recorrencia.desconto,
        desconto_dias_antecipacao: recorrencia.desconto_dias_antecipacao
      };

      axios
        .post(action, body)
        .then(res => {
          this.saveRecorrencia = false;
          if (!res.data.success) {
             if(res.data.errors.dtini) {
              this.errors.dtini = res.data.errors;
              return;
             } else {
               this.$q.notify({
                message: 'Não foi possível salvar a recorrência!',
                color: 'orange',
                position: 'top-right',
              });
              this.errors = res.data.errors;
              return;
             }
          }

          this.$store.commit("newRecorrencia", res.data.data.recorrencia);

          this.$q.notify({
            message: 'Recorrência salva!',
            color: 'green',
            position: 'top-right',
          });

          this.listarRecorrencias();
          this.closeModal();
          this.closeModalTrial();
          this.closeModalComissoes();
          this.recorrencia = this.newRecorrencia();
        })
        .catch(() => {
          this.$q.notify({
            message:
              'Não foi possível salvar a recorrência, ou você não possui permissão para isto!',
            color: 'orange',
            position: 'top-right',
          });
          this.saveRecorrencia = false;
        });
    },
    newRecorrencia() {
      return {
        id: null,
        ativo: true,
        congelado: false,
        periodo: 1,
        convenio_id: null,
        numeroconvenio: '',
        dependente_id: null,
        descricao: '',
        valor: 0.0,
        plano_id: null,
        valor_ativacao: 0.0,
        quantidade: 1,
        dia_cobranca: null,
        cadastros_recorrencias_items: [],
        cadastros_recorrencias_descontos: [],
        cadastros_recorrencias_parcelas: [],
        desconto: null,
        desconto_dias_antecipacao: 0
      };
    },
    getAction() {
      let url = '/cadastros/add-recorrencia/' + this.cadastroId;
      if (this.recorrencia.id) {
        url = '/cadastros/edit-recorrencia/' + this.cadastroId + '/' + this.recorrencia.id;
      }
      return url;
    },
    addDesconto() {
      const errorsDesconto = {};

      if (Number.isNaN(this.desconto.valor_desconto) || this.desconto.valor_desconto <= 0) {
        errorsDesconto['valor_desconto'] = ['Valor inválido'];
      }
      if (!this.desconto.dtfim || !moment(this.desconto.dtfim, 'DD/MM/YYYY').isValid()) {
        errorsDesconto['dtfim'] = ['Data inválida'];
      }

      if (Object.keys(errorsDesconto).length > 0) {
        this.errorsDesconto = errorsDesconto;
        return;
      }

      const desconto = JSON.parse(JSON.stringify(this.desconto));
      desconto.dtfim = moment(desconto.dtfim, 'DD/MM/YYYY').format('YYYY/MM/DD');

      this.recorrencia.cadastros_recorrencias_descontos.push(desconto);
      this.errorsDesconto = [];

      this.clearDesconto();
      this.pulaCampo('valorDesconto');
    },
    removeDesconto(descontoIndex) {
      this.recorrencia.cadastros_recorrencias_descontos.splice(descontoIndex, 1);
      this.pulaCampo('valorDesconto');
    },
    addItem() {
      const errorsItem = {};

      if (Number.isNaN(this.item.quantidade) || this.item.quantidade <= 0) {
        errorsItem['quantidade'] = ['Valor inválido'];
      }

      if (Number.isNaN(this.item.valor) || this.item.valor <= 0) {
        errorsItem['valor'] = ['Valor inválido'];
      }

      if (String(this.item.descricao) === '') {
        errorsItem['descricao'] = ['Este campo não pode ser em branco'];
      }

      if (Object.keys(errorsItem).length > 0) {
        this.errorsItem = errorsItem;
        return;
      }

      this.OpenDialogAddItem = true;
    },
    confirmAddItem() {
      const errorsItem = {};

      if (this.item.dtini && !moment(this.item.dtini, 'DD/MM/YYYY').isValid()) {
        errorsItem['dtini'] = ['Data inválida'];
      }

      if (this.item.dtfim && !moment(this.item.dtfim, 'DD/MM/YYYY').isValid()) {
        errorsItem['dtfim'] = ['Data inválida'];
      }

      if (Object.keys(errorsItem).length > 0) {
        this.errorsItem = errorsItem;
        return;
      }

      const item = JSON.parse(JSON.stringify(this.item));

      if (item.dtini) {
        item.dtini = moment(item.dtini, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }

      if (item.dtfim) {
        item.dtfim = moment(item.dtfim, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }

      const items = this.cloneRecorrenciasItems();

      if (item.planodeconta_id) {
        item.planodeconta = this.planosdecontas.find(
          planodeconta => planodeconta.id === item.planodeconta_id
        );
      }

      items.push(item);
      let valorItems = 0.00;
      items.map(item => valorItems += +item.valor);

      this.recorrencia.valor = valorItems;
      this.recorrencia.cadastros_recorrencias_items = items;
      this.errorsItem = [];

      this.clearItem();
      this.closeModalItem();
      this.pulaCampo('quantidadeItem', 10);
    },
    removeItem(itemIndex) {
      const items = this.cloneRecorrenciasItems();
      items.splice(itemIndex, 1);

      let valorItems = 0;
      items.map(item => valorItems += +item.valor);

      this.recorrencia.valor = valorItems;
      this.recorrencia.cadastros_recorrencias_items = items;
      this.pulaCampo('quantidadeItem');
    },
    addParcela() {
      const errorsParcelas = {};

      if (this.parcela.descricao.trim() === '') {
        errorsParcelas['descricao'] = ['Este campo não pode ser vazio'];
      }
      if (Number.isNaN(this.parcela.valor) || this.parcela.valor <= 0) {
        errorsParcelas['valor'] = ['Valor inválido'];
      }
      if (!this.parcela.vencimento || !moment(this.parcela.vencimento, 'DD/MM/YYYY').isValid()) {
        errorsParcelas['vencimento'] = ['Data inválida'];
      }

      if (Object.keys(errorsParcelas).length > 0) {
        this.errorsParcelas = errorsParcelas;
        return;
      }

      const parcela = JSON.parse(JSON.stringify(this.parcela));
      parcela.vencimento = moment(parcela.vencimento, 'DD/MM/YYYY').format('YYYY/MM/DD');

      const parcelas = this.cloneRecorrenciasParcelas();
      parcelas.push(parcela);

      this.recorrencia.cadastros_recorrencias_parcelas = parcelas;
      this.errorsParcelas = [];

      this.clearParcela();
      this.pulaCampo('descricaoParcela');
    },
    removeParcela(parcelaIndex) {
      const parcelas = this.cloneRecorrenciasParcelas();
      parcelas.splice(parcelaIndex, 1);

      this.recorrencia.cadastros_recorrencias_parcelas = parcelas;
      this.pulaCampo('descricaoParcela');
    },
    addComissao() {
      this.loadingSalvarComissao = true;

      const comissao = JSON.parse(JSON.stringify(this.comissao));
      comissao.cadastro_id = comissao.cadastro.id;
      comissao.planodeconta_id = comissao.planodeconta
        ? comissao.planodeconta.id
        : comissao.planodeconta;
      comissao.dtini = moment(comissao.dtini, 'DD/MM/YYYY').format('YYYY/MM/DD');

      if (comissao.dtfim) {
        comissao.dtfim = moment(comissao.dtfim, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }

      axios
        .post(`/cadastros/add-recorrencia-comissao/${this.recorrencia.id}`, comissao)
        .then(res => {
          this.loadingSalvarComissao = false;

          this.$q.notify({
            color: res.data.success ? 'green' : 'orange',
            message: res.data.message,
            position: 'top-right',
          });

          if (!res.data.success) {
            this.errorsComissoes = res.data.errors;
            return;
          }
          this.closeModalComissoes();
          this.abrirModalComissoes(this.recorrencia);
          this.pulaCampo('comissoesCadastro');
        })
        .catch(() => {
          this.loadingSalvarComissao = false;
          this.$q.notify({
            color: 'red',
            message: 'Houve um erro ao adicionar a comissão',
            position: 'top-right',
          });
        });
    },
    removeComissao(id) {
      if (this.comissoesSalvando.includes(id)) return;

      this.comissoesSalvando.push(id);

      axios
        .post(`/cadastros/delete-recorrencia-comissao/${id}`)
        .then(res => {
          this.comissoesSalvando.splice(this.comissoesSalvando.indexOf(id), 1);

          this.$q.notify({
            color: res.data.success ? 'green' : 'orange',
            message: res.data.message,
            position: 'top-right',
          });

          this.abrirModalComissoes(this.recorrencia);
        })
        .catch(() => {
          this.splice(this.comissoesSalvando.indexOf(id), 1);

          this.$q.notify({
            color: 'red',
            message: 'Houve um erro ao adicionar a comissão',
            position: 'top-right',
          });
        });
    },
    cloneRecorrenciasItems() {
      return JSON.parse(JSON.stringify(this.recorrencia.cadastros_recorrencias_items));
    },
    cloneRecorrenciasParcelas() {
      return JSON.parse(JSON.stringify(this.recorrencia.cadastros_recorrencias_parcelas));
    },
    cloneRecorrenciasComissoes() {
      return JSON.parse(JSON.stringify(this.recorrencia.cadastros_recorrencias_comissoes));
    },
    /**
    importarPlano() {
      if (!this.plano || this.importandoPlano) {
        return;
      }

      if (!this.recorrencia.plano_id) {
        this.importarPlanoRequest();
        return;
      }

      const tipoPlano = this.plano.id !== this.recorrencia.plano_id ? "outro plano" : "o plano novamente";

      this.$q.dialog({
        title: "Importar Plano",
        message: `Tem certeza que deseja importar ${tipoPlano}? Todos os descontos atuais serão
          substituidos pelo do plano (caso tenha).`,
        cancel: true,
        persistent: true,
      })
      .onOk(() => {
        this.importarPlano();
      });
    },
    importarPlanoRequest() {
      this.importandoPlano = true;

      const cadastroId = this.cadastroId;
      const recorrenciaId = this.recorrencia.id ? this.recorrencia.id : '';

      axios
        .post(`/cadastros/importar-plano/${cadastroId}/${recorrenciaId}`, { plano_id: this.plano.id })
        .then(res => {
          this.importandoPlano = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? "green" : "orange",
            position: "top-right",
          });

          if (!res.data.success) {
            return;
          }

          this.recorrencia = res.data.recorrencia;
          this.descontos = res.data.recorrencia.cadastros_recorrencias_descontos;

          this.listarRecorrencias();
        })
        .catch(() => {
          this.importandoPlano = false;
          this.$q.notify({
            message: "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right",
          });
        });
    },
     */
    formatDate(data) {
      if (!data) {
        return '';
      }

      return moment(data, 'YYYY/MM/DD').format('DD/MM/YYYY');
    },
    getPeriodoDescricao(intervalo) {
      intervalo = parseInt(intervalo);

      switch (intervalo) {
        case 1:
          return 'Mensal';
        case 2:
          return 'Bimestral';
        case 3:
          return 'Trimestral';
        case 6:
          return 'Semestral';
        case 12:
          return 'Anual';
        default:
          return intervalo + ' meses';
      }
    },
    aprovarRecorrencia(recorrencia) {
      this.$q
        .dialog({
          title: recorrencia.descricao,
          message: 'Tem certeza que deseja aprovar a recorrência?',
          persistent: true,
          cancel: true,
        })
        .onOk(() => {
          this.aprovandoRecorrencia = recorrencia.id;

          axios
            .post(`/cadastros/aprovar-recorrencia/${recorrencia.id}`)
            .then(res => {
              this.aprovandoRecorrencia = null;

              this.$q.notify({
                color: res.data.success ? 'green' : 'orange',
                message: res.data.message,
                position: 'top-right',
              });

              if (!res.data.success) {
                return;
              }

              const recorrenciaIndex = this.recorrencias.indexOf(recorrencia);
              this.recorrencias.splice(recorrenciaIndex, 1, res.data.recorrencia);
            })
            .catch(() => {
              this.aprovandoRecorrencia = null;

              this.$q.notify({
                color: 'red',
                message: 'Houve um erro ao aprovar a recorrência',
                position: 'top-right',
              });
            });
        });
    },
    faturarCliente() {
      this.$store.commit('saveFaturamento', {
        ...this.saveFaturamento,
        cadastros_ids: [parseInt(this.$route.params.id)],
      });
      this.$store.dispatch('faturar');
    },
    loadPlanosdecontas() {
      axios
        .get('/plano-contas?cd=C')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.planosdecontas = res.data.planodecontas;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    loadPlanos() {
      axios
        .get('/planos')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }
          this.planosOptions = res.data.planos.map(function(item) {
            var periodo = 'Anual';
            if(item.periodo == 1){
               periodo = 'Mensal';
            }
            return { id: item.id, nome: item.nome + ' - ' + periodo };
          });
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    loadPlanosdecontasComissoes() {
      axios
        .get('/plano-contas?cd=D')
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.planosdecontasComissoes = res.data.planodecontas;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Houve um erro ao carregar os planos de contas',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    filterPlanosdecontas(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.planosdecontasOptions = this.planosdecontas.filter(planodecontas =>
          planodecontas.titulo.toLowerCase().includes(needle)
        );
      });
    },
    filterPlanosdecontasComissoes(val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();
        this.planosdecontasOptions = this.planosdecontasComissoes.filter(planodecontas =>
          planodecontas.titulo.toLowerCase().includes(needle)
        );
      });
    },
    notificarSemPermissao(message) {
      this.$q.notify({
        message: message,
        color: 'red',
        position: 'top-right',
      });
    },
    closeModalTrial() {
      this.OpenDialogTrial = false;
    },
    closeModalComissoes() {
      this.OpenDialogComissoes = false;
    },
    buscarCadastro(val, update) {
      update(() => {
        axios.get(`/cadastros?ativo=1&fornecedor=1&funcionario=1&busca=${val}`).then(res => {
          if (!res.data.success) return;
          this.cadastros = res.data.data.cadastros;
        });
      });
    },
    handleCongeladoDtfim(value) {
      this.recorrenciaSelecionada.congelado_dtfim = value;
      this.$refs.qDateCongelamentoDtfim.hide();
    },
    handleDtiniDtfimComissao() {
      const dtini = moment(this.comissao.dtini, 'DD/MM/YYYY');
      const dtfim = moment(this.comissao.dtfim, 'DD/MM/YYYY');

      if (!dtini.isValid() || !dtfim.isValid()) return;

      this.quantidadeMesesComissao = dtfim.diff(dtini, 'months');
    },
    handleQuantidadeMesesComissao() {
      const dtini = moment(this.comissao.dtini, 'DD/MM/YYYY');

      if (!dtini.isValid()) return;

      this.comissao.dtfim = dtini.add(this.quantidadeMesesComissao, 'months').format('DD/MM/YYYY');
    },
    getTotalContratacao(recorrencia) {
      return recorrencia.valor / recorrencia.periodo + recorrencia.valor_ativacao;
    },
    checkUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('modal_recorrencia') === '1') {
        const recorrenciaId = urlParams.get('recorrencia_id');
        this.modalAdicionarEditar(recorrenciaId ? { id: recorrenciaId } : null);
      }
    }
  },
  computed: {
    ...mapGetters(['saveFaturamento']),
    columns() {
      let columns = [
        {
          name: 'ativo',
          required: true,
          label: 'Ativo',
          align: 'center',
          field: row => row.ativo,
          format: val => `${val}`,
          sortable: true,
        },
        {
          name: 'descricao',
          label: 'Descrição',
          field: row => row.descricao,
          align: 'center',
          sortable: true,
        },
        {
          name: 'valor',
          label: 'Mensalidade',
          field: row => row.valor,
          align: 'center',
          sortable: true,
        },
        {
          name: "valor_ativacao",
          required: true,
          align: "center",
          label: "Adesão",
          field: row => row.valor_ativacao,
        },
        {
          name: "total_contratacao",
          required: true,
          align: "center",
          label: "Total Contratação",
          field: row => row.valor / row.periodo + row.valor_ativacao,
        },
        { name: 'tdfora' },
      ];

      if (this.exibirCampoVinculo) {
        columns.splice(1, 0, {
          name: 'dependente',
          label: 'Vínculo',
          field: row => row.dependente,
          align: 'center',
          sortable: true,
        });
      }

      return columns;
    },
    cadastroId() {
      return this.$route.params.id;
    },
    utilizarTrial() {
      return (
        this.opcoes.integracoes_piperun_utilizar_trial &&
        parseInt(this.opcoes.integracoes_piperun_utilizar_trial)
      );
    },
    utilizarComissoes() {
      return (
        this.opcoes.integracoes_piperun_utilizar_comissoes &&
        parseInt(this.opcoes.integracoes_piperun_utilizar_comissoes)
      );
    },
    editarPlanodecontas() {
      return (
        this.opcoes.integracoes_piperun_planodeconta_recorrencias &&
        parseInt(this.opcoes.integracoes_piperun_planodeconta_recorrencias)
      );
    },
    exibirCampoVinculo() {
      return (
        this.opcoes.integracoes_piperun_exibir_campo_vinculo &&
        parseInt(this.opcoes.integracoes_piperun_exibir_campo_vinculo)
      );
    },
    exibirCampoConvenio() {
      return (
        this.opcoes.integracoes_piperun_exibir_campo_convenio &&
        parseInt(this.opcoes.integracoes_piperun_exibir_campo_convenio)
      );
    },
    exibirCampoPlano() {
      return (
        this.opcoes.integracoes_piperun_exibir_campo_plano &&
        parseInt(this.opcoes.integracoes_piperun_exibir_campo_plano)
      );
    },
    comissaoOrigemValorOptions() {
      return [
        {
          label: 'Fixo',
          value: 'fixo',
        },
        {
          label: 'Recorrência',
          value: 'recorrencia',
        },
        {
          label: 'Parcela',
          value: 'parcela',
          disable: this.comissao.item_quando && this.comissao.item_quando !== 'parcela',
        },
      ];
    },
  },
  watch: {
    qrecorrencias(value) {
      if (!value) return;

      this.listarRecorrencias();
    },
    OpenDialogRecorrencias(value) {
      if (!value) return;

      this.planosOptions = [];

      this.loadPlanosdecontas();
      this.loadPlanos();
    },
    'recorrencia.cadastros_recorrencias_items'(items, oldItems) {
      if (!Array.isArray(items)) {
        return;
      }
      if (!oldItems) {
        oldItems = [];
      }

      const valorAntigo = oldItems.reduce((total, item) => total + Number(item.valor * 100), 0) / 100;
      const quantidadeAntiga = oldItems.reduce((total, item) => total + Number(item.quantidade), 0);
      const descricaoAntiga = oldItems
        .map(item => `(${item.quantidade}) ${item.descricao}`)
        .join(' | ');

      if (Number(this.recorrencia.valor) === 0 || Number(this.recorrencia.valor) === valorAntigo) {
        this.recorrencia.valor = items.reduce((total, item) => total + parseInt(item.valor * 100), 0) / 100;
      }

      if (
        items.length > 0 &&
        (Number(this.recorrencia.quantidade) <= 1 ||
          Number(this.recorrencia.quantidade) === quantidadeAntiga)
      ) {
        this.recorrencia.quantidade = items.reduce(
          (total, item) => total + Number(item.quantidade),
          0
        );
      }

      if (!this.recorrencia.descricao || this.recorrencia.descricao === descricaoAntiga) {
        this.recorrencia.descricao = items
          .map(item => `(${item.quantidade}) ${item.descricao}`)
          .join(' | ');
      }
    },
    'recorrencia.cadastros_recorrencias_parcelas'(parcelas, oldParcelas) {
      if (!Array.isArray(parcelas)) {
        return;
      }
      if (!oldParcelas) {
        oldParcelas = [];
      }

      const valorAntigo = oldParcelas.reduce((total, item) => total + Number(item.valor), 0);

      if (
        Number(this.recorrencia.valor_ativacao) === 0 ||
        Number(this.recorrencia.valor_ativacao) === valorAntigo
      ) {
        this.recorrencia.valor_ativacao = parcelas.reduce(
          (total, item) => total + Number(item.valor),
          0
        );
      }
    },
    'comissao.item_quando'(itemQuando) {
      if (itemQuando !== 'parcela' && this.comissao.origem_valor === 'parcela') {
        this.comissao.origem_valor = null;
      }
    },
    'comissao.origem_valor'(origemValor) {
      if (origemValor === 'parcela') {
        this.comissao.item_quando = 'parcela';
      }
    },
  },
  beforeMount() {
    bus.$off('loadRecorrenciasCadastro');
  },
  mounted() {
    bus.$on('loadRecorrenciasCadastro', () => this.listarRecorrencias());

    this.checkUrl();
  },
  destroyed() {
    bus.$off('loadRecorrenciasCadastro');
  },
};
</script>

<style lang="stylus" scoped>
.text-h6, .text-subtitle2 {
  font-weight: 300;
}

.q-table__card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.nova-recorrencia-card {
  max-width: 400px;
  width: 90%;
}

.nova-recorrencia-card.open {
  max-width: 1000px;
}

.q-field--labeled .q-field__native {
  padding-bottom: 0;
}
</style>
