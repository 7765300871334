<template>
  <div id="cardEstoqueEmpresa"  v-if="this.estoqueEmpresa">
      <q-card-section class="q-pb-sm">
        <div class="row items-center no-wrap">
          <div class="col">
            <div class="text-grey-9" style="font-size: 16px;">
              <router-link to="/estoque" style="text-decoration: none; color: black">
                Estoque da Empresa
              </router-link>
            </div>
          </div>

        </div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <table>
          <thead>
            <tr>
              <th>Retaguarda</th>
              <th>Operadores</th>
              <th>Empresa</th>
              <th>Produto</th>
              <th>Total (R$)</th>
            </tr>
          </thead>
          <tbody
          >
            <tr
              v-for="produto in this.estoqueEmpresa"
              :key="produto.id"
              >
              <td class="text-right">{{ produto.qtd_empresa }}</td>
              <td class="text-right">{{ produto.qtd_funcionarios }}</td>
              <td class="text-right text-bold">{{ produto.qtd_funcionarios + produto.qtd_empresa }}</td>
              <td class="">
                {{ produto.nome }}
                {{ tipo(produto.tpcontrole) }} {{ informacoes(produto) }}  {{ produto.vencimento | formatDate }}
              </td>
              <td class="text-right text-bold">{{ (parseFloat( produto.vlrvenda) * (produto.qtd_funcionarios + produto.qtd_empresa)).toFixed(2) | currency }}</td>
            </tr>

            <tr v-if="this.estoqueEmpresa.length === 0">
              <td colspan="2">
                Nenhum produto no estoque
              </td>
            </tr>
          </tbody>
        </table>
      </q-card-section>
      <div class="q-mx-md" v-if="this.estoqueEmpresa.length !== 0">
        <q-separator />
        <div
        class="flex justify-between"

        >
          <span>Valor de Custo Previsto</span>
          <strong>R$ {{custoTotalBruto | currency}}</strong>
        </div>
        <div
        class="flex justify-between"
        >
          <span>Valor de Venda Previsto</span>
          <strong>R$ {{valorVendaTotalBruto | currency}}</strong>
        </div>
        <q-separator />
        <div
        class="flex justify-between q-pb-md"
        >
          <strong>Lucro Bruto Previsto</strong>
          <strong>R$ {{valorVendaTotalBruto - custoTotalBruto | currency}}</strong>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "card-estoque-loterica",
    props: {
      estoqueEmpresa: Array
  },
  methods: {
    tipo(tipo) {
      if (tipo === "Nenhum") {
        return null;
      }

      return tipo;
    },
    informacoes(produto) {
      switch(produto.tpcontrole) {
        case "Extração":
          return produto.extracao;
        case "Concurso":
          return produto.concurso;
        case "Evento":
          return produto.evento;
        default:
          return null;
      }
    },
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "11",
      });
    },
  },
  computed:{
    valorVendaTotalBruto(){
      return this.estoqueEmpresa.reduce((soma, item)=>{
        const valorVenda = parseFloat(item.vlrvenda);
        const quantidadeEmpresa = item.qtd_funcionarios + item.qtd_empresa;
        const subtotal = valorVenda * quantidadeEmpresa;
        return soma + subtotal;
      }, 0);
    },
    custoTotalBruto(){
      return this.estoqueEmpresa.reduce((soma, item)=>{
        const valorCusto = parseFloat(item.vlrcusto);
        const quantidadeEmpresa = item.qtd_funcionarios + item.qtd_empresa;
        const subtotal = valorCusto * quantidadeEmpresa;
        return soma + subtotal;
      }, 0);
    }
  }
};
</script>

<style lang="stylus" scoped>
</style>
