<template>
  <div id="cardGrafReceita">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            {{ fetchUser.db.clientesFormaPagamento.title }}
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section class="q-py-none">
      <PieChart v-if="exibirGrafico" :series="fetchUser.db.clientesFormaPagamento.series"
        :options="fetchUser.db.clientesFormaPagamento.options" :height="280" />
      <p v-else class="text-center q-mt-lg">Nenhuma forma de pagamento com clientes utilizando</p>
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PieChart from "../Graficos/pie-chart.vue";
export default {
  name: "card-graf-clientes-forma-pagamento",
  components: {
    PieChart
  },
  data() { },
  methods: {
    atualizaDash() {
      this.$store.dispatch("fetchUser");
    },
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "9",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser", "layout"]),
    exibirGrafico() {
      return !!this.fetchUser.db.clientesFormaPagamento.series;
    },
  },
  mounted() { }
};
</script>

<style lang="stylus" scoped></style>
