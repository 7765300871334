<template>
  <div id="cardReceita">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            Receitas Liquidadas
          </div>
          <div class="text-bold text-grey-9" style="font-size: 22px;">
            {{ fetchUser.db.receitas.Liquidados | currency }}
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <p>
        <span v-if="fetchUser.db.percent.mesReceitas > 0" class="text-positive">
          {{ fetchUser.db.percent.mesReceitas }}%
          <i class="fa fa-level-up"></i>
        </span>
        <span v-if="fetchUser.db.percent.mesReceitas < 0" class="text-negative">
          {{ fetchUser.db.percent.mesReceitas }}%
          <i class="fa fa-level-down"></i>
        </span>
        período anterior<br>
        R$ <strong>{{ fetchUser.db.percent.receitasAnterior | currency }}</strong>
      </p>
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "card-receita",
  data() {
    return {};
  },
  methods: {
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "2",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser", "dtanterior", "layout"])
  },
  mounted() { }
};
</script>

<style lang="stylus" scoped></style>
