<template>
  <div id="cardMeuCaixa" v-if="fetchUser && fetchUser.db.meuCaixa">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            Meu Caixa
          </div>
          <div class="text-bold text-grey-9" style="font-size: 22px;" v-if="fetchUser.db.meuCaixa.caixa">
            <router-link :to="`/caixas/${fetchUser.db.meuCaixa.caixa.id}`" style="text-decoration: none; color: black">
              {{ fetchUser.db.meuCaixa.caixa.caixa_nome }} -
              Sessão {{ fetchUser.db.meuCaixa.caixa.sessao }} -
              {{ new Date(fetchUser.db.meuCaixa.caixa.dtmovimento) | formatDate }}
            </router-link>
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section v-if="fetchUser.db.meuCaixa.totais === undefined">
      Você não possui um último caixa!
    </q-card-section>

    <q-card-section v-else class="q-py-none">
      <q-list
        dense
        class="q-mb-md"
      >
        <q-item>
          <q-item-section avatar class="sinal-totalizador">
            <q-item-label>(+)</q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item-label>Saldo Inicial</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label>R$ {{fetchUser.db.meuCaixa.totais.total_saldo_inicial |currency}}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar class="sinal-totalizador">
            <q-item-label>(+)</q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item-label lines="1">Vendas</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label>R$ {{fetchUser.db.meuCaixa.totais.total_vendas |currency}}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar class="sinal-totalizador">
            <q-item-label></q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item-label lines="1">Vendas Fiado</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label>R$ {{fetchUser.db.meuCaixa.totais.total_fiado |currency}}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar class="sinal-totalizador">
            <q-item-label>(+)</q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item-label lines="1">Resumo do Operador</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label>R$ {{fetchUser.db.meuCaixa.totais.total_resumo |currency}}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar class="sinal-totalizador">
            <q-item-label>(+)</q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item-label lines="1">Reforço</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label>R$ {{fetchUser.db.meuCaixa.totais.total_reforco |currency}}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar class="sinal-totalizador">
            <q-item-label>(+)</q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item-label lines="1">Recebimento de Contas</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label>R$ {{fetchUser.db.meuCaixa.totais.total_recebimentos |currency}}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar class="sinal-totalizador">
            <q-item-label>(-)</q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item-label lines="1">Pagamento de Contas</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label>R$ {{fetchUser.db.meuCaixa.totais.total_pagamentos |currency}}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar class="sinal-totalizador">
            <q-item-label>(-)</q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item-label lines="1">Sangria</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label>R$ {{fetchUser.db.meuCaixa.totais.total_sangria |currency}}</q-item-label>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section avatar class="sinal-totalizador">
            <q-item-label>(-)</q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item-label lines="1">Saldo Final</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label>R$ {{fetchUser.db.meuCaixa.totais.total_saldo_final |currency}}</q-item-label>
          </q-item-section>
        </q-item>

        <q-separator />

        <q-item>
          <q-item-section>
            <q-item-label lines="1" class="text-bold">Total em Caixa</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-item-label :class="fetchUser.db.meuCaixa.totais.total > 0 ? 'text-green' : 'text-red' ">R$ {{fetchUser.db.meuCaixa.totais.total |currency}}</q-item-label>
          </q-item-section>
        </q-item>

      </q-list>

      <p>Aberto em {{ fetchUser.db.meuCaixa.caixa.created | formatDate }}, por {{ fetchUser.db.meuCaixa.caixa.func_aber_nome }}</p>
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "card-meu-caixa",
  data() {
    return {};
  },
  methods: {
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "12",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser"]),
  },
};
</script>

<style lang="stylus" scoped>
  #cardMeuCaixa
    height:100%;
    overflow-x: auto;

  .sinal-totalizador
    min-width: 32px
</style>
