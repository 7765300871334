<template>
  <div id="cardGrafDespesa">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            {{ fetchUser.db.despesas.title }}
          </div>
          <div class="text-bold text-grey-9" style="font-size: 22px;">
            {{ fetchUser.db.despesas.subtitle }}
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <StackedBarChart :series="getChartSeries()" :height="115" :options="getCharOptions()" />
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StackedBarChart from "../Graficos/stacked-bar-chart.vue";

export default {
  name: "card-graf-despesa",
  components: {
    StackedBarChart
  },
  methods: {
    atualizaDash() {
      this.$store.dispatch("fetchUser");
    },
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "1",
      });
    },
    getCharOptions() {
      return {
        xaxis: {
          categories: [this.fetchUser.db.despesas.mesReferencia],
        }
      };
    },
    getChartSeries() {
      return this.fetchUser.db.despesas.series;
    }
  },
  computed: {
    ...mapGetters([
      "fetchUser",
      "layout",
    ]),
  },
};
</script>

<style lang="stylus" scoped></style>