import Vue from 'vue';
let moment = require('moment');

Vue.filter('formatDate', value => {
  if (!value) return '';
  return moment.utc(value).format('DD/MM/YY');
});

Vue.filter('formatDateTime', value => {
  if (!value) return '';
  return moment(value).format('DD/MM/YYYY HH:mm') + 'h';
});

Vue.filter('formatTime', value => {
  if (!value) return '';
  return moment(value).format('HH:mm');
});

Vue.filter('fromNow', value => {
  if (!value) return '';
  return moment(value).fromNow();
});

Vue.filter('currency', value => {
  if (!value) return '0,00';
  var val = (value / 1).toFixed(2).replace('.', ',');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

Vue.filter('decode', value => {
  return JSON.parse(value);
});

Vue.filter('hora', value => {
  let hora = moment(value).format('HH:mm');
  return hora;
});

Vue.filter('cnpj', value => {
  const cnpj = String(value).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  return cnpj;
});

Vue.filter('value', (value, type) => {
  if (type === '%') {
    return `${value} %`;
  }
  return `R$ ${value}`;
});

Vue.filter('truncate', (value, size = 40) => {
  value = value ? String(value) : "";

  if (value.length > size) {
    return value.slice(0, size - 3) + '...';
  }

  return value;
});
