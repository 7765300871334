<template>
  <div id="cardMeuEstoque" v-if="fetchUser && fetchUser.db.meuEstoque">
    <q-card-section class="q-pb-sm">
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            Meu Estoque
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section class="q-pt-none">
      <table>
        <tbody>
          <tr
            v-for="produto in fetchUser.db.meuEstoque"
            :key="produto.produto_id"
            >
            <td class="text-right text-bold">{{ produto.quantidade }}</td>
            <td>{{ produto.nome }} {{ tipo(produto.tpcontrole) }} {{ informacoes(produto) }}  {{produto.vencimento | formatDate}}</td>
          </tr>

          <tr v-if="this.fetchUser.db.meuEstoque.length === 0">
            <td colspan="2">
              Nenhum produto no estoque
            </td>
          </tr>

        </tbody>
      </table>
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "card-meu-estoque",
  data() {
    return {};
  },
  methods: {
    tipo(tipo) {
      if (tipo === "Nenhum") {
        return null;
      }

      return tipo;
    },
    informacoes(produto) {
      switch(produto.tpcontrole) {
        case "Extração":
          return produto.extracao;
        case "Concurso":
          return produto.concurso;
        case "Evento":
          return produto.evento;
        default:
          return null;
      }
    },
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "10",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser"]),
  },
};
</script>

<style lang="stylus" scoped></style>
