<template>
  <div id="cardGrafAtivo">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            Sócios/Não Sócios
          </div>
          <div v-show="!loadingDash" class="text-bold text-grey-9" style="font-size: 22px;">
            {{ getRatioAI }}
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section v-if="loadingDash">
      <div class="q-pa-md">
        <div class="q-col-gutter-md row">
          <div class="col-12 col-md-12 text-center">
            <q-spinner color="blue-7" size="4em" />
          </div>
        </div>
      </div>
    </q-card-section>

    <q-card-section v-if="!loadingDash">
      <PieChart :series="getChartSeries()" :options="getChartOptions()" :height="250" />
    </q-card-section>

    <q-card-actions align="center">
      <q-btn-group flat>
        <q-btn flat round icon="chevron_left" class="white text-blue-7" clickable @click="changeMonth('prev')" />
        <q-btn flat :label="returnMonth()" class="white text-blue-7" />
        <q-btn flat round icon="chevron_right" class="white text-blue-7" clickable @click="changeMonth('next')" />
      </q-btn-group>
    </q-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PieChart from "../Graficos/pie-chart.vue";
const moment = require("moment");
export default {
  name: "card-graf-ativo",
  components: {
    PieChart
  },
  methods: {
    returnMonth() {
      let month = moment.utc(this.fetchUser.db.dtini).format("M");
      let year = moment.utc(this.fetchUser.db.dtini).format("YYYY");
      return this.mesShort[month] + "/" + year;
    },
    changeMonth(value) {
      let data = {
        dtini: null,
        dtfim: null
      };
      if (value === "prev") {
        data.dtini = moment.utc(this.fetchUser.db.dtini).subtract(1, "months");
        data.dtfim = moment.utc(this.fetchUser.db.dtfim).subtract(1, "months");
      } else {
        data.dtini = moment.utc(this.fetchUser.db.dtini).add(1, "months");
        data.dtfim = moment.utc(this.fetchUser.db.dtfim).add(1, "months");
      }
      data.dtini = data.dtini.format("DD-MM-YYYY");
      data.dtfim = data.dtfim.format("DD-MM-YYYY");
      this.$store.dispatch("dashClientes", data);
    },
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "6",
      });
    },
    getChartSeries() {
      return [
        this.fetchUser.db.contadores["clientes_ativos"],
        this.fetchUser.db.contadores["clientes_inativos"]
      ];
    },
    getChartOptions() {
      return {
        labels: ["Sócios", "Não sócios"],
        colors: ["#008ffb", "#ff4560"]
      };
    }
  },
  computed: {
    ...mapGetters([
      "fetchUser",
      "getRatioAI",
      "percentCards",
      "loadingDash",
      "handlerSocios"
    ]),
  }
};
</script>