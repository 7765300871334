<template>
  <div id="modalMapaResgatesNovo">
    <q-dialog v-model="openDialogNovoMapaResgates" persistent style="max-width: 100%;">
      <q-card v-show="!loadingDialogNovoMapaResgates" class="q-px-lg" id="novo-mapa-resgate-card">

        <q-card-section class="row items-center">
          <div class="text-h6">Mapa de Resgates</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="cancela()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <q-form @submit="adicionarResgate">
            <q-markup-table class="no-shadow">
              <thead>
                <tr>
                  <th align="left">Ano</th>
                  <th align="left">Evento</th>
                  <th align="left">Quantidade</th>
                  <th align="right">Valor</th>
                  <th align="right">Comissão</th>
                  <th align="right">Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!mapaResgates.visualizar">
                  <td v-bind:class="{'form-error': Object.keys(errors).length > 0 }" style="width: 150px;">
                    <q-select
                      dense
                      outlined
                      v-model="form.ano"
                      :options="anos"
                      :error="Boolean(errors.ano)"
                      ref="anoRef"
                    >
                      <template v-slot:error>
                        <span v-for="(error, i) in errors.ano" :key="error + i">{{error}}</span>
                      </template>
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">
                            Sem resultados
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </td>

                  <td v-bind:class="{'form-error': Object.keys(errors).length > 0 }">
                    <q-select
                      dense
                      v-model="form.evento_obj"
                      outlined
                      :loading="loadingEventos"
                      :options="eventos"
                      :option-value="evento => evento.id"
                      :option-label="evento => evento.nome"
                      :error="Boolean(errors.evento)"
                      ref="eventoRef"
                      @input="() => pulaProx('quantidade')"
                    >
                      <template v-slot:error>
                        <span v-for="(error, i) in errors.evento" :key="error + i">{{error}}</span>
                      </template>
                      <template v-slot:no-option>
                        <q-item>
                          <q-item-section class="text-grey">
                            Sem resultados
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </td>

                  <td v-bind:class="{'form-error': Object.keys(errors).length > 0 }" style="width: 120px">
                    <q-input
                      dense
                      v-model.number="form.quantidade"
                      mask="####"
                      outlined
                      type="text"
                      ref="quantidadeRef"
                      :error="Boolean(errors.quantidade)"
                      @keydown.enter.prevent="pulaProx('valor')"
                    >
                      <template v-slot:error>
                        <span v-for="(error, i) in errors.quantidade" :key="error + i">{{error}}</span>
                      </template>
                    </q-input>
                  </td>

                  <td align="right">
                    <div>
                      <q-field
                        dense
                        outlined
                        v-model="form.valor"
                        :error="Boolean(errors.valor)"
                        class="mapa-resgates-valor-field"
                      >
                        <template
                          v-slot:control="{ id, floatingLabel, value, emitValue }"
                        >
                          <money
                            ref="valorRef"
                            class="q-field__native text-right"
                            :id="id"
                            :value="value"
                            @input="emitValue"
                            v-bind="moneyFormat"
                            v-show="floatingLabel"
                          >
                          </money>
                        </template>

                        <template v-slot:error>
                          <span v-for="(error, i) in errors.valor" :key="error + i">
                          {{ error }}
                          </span>
                        </template>
                      </q-field>
                    </div>
                  </td>

                  <td align="right">{{ formataValor(calculaComissao(form.quantidade)) }}</td>

                  <td align="right">{{ formataValor(form.total) }}</td>

                  <td class="tdfora">
                    <div class="divfora" style="display: initial;">
                      <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                        <q-btn
                          :loading="loadingAdicionarItem"
                          color="blue"
                          icon="add"
                          type="submit"
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr
                  v-for="resgate in mapaResgates.mapa_resgates_items"
                  :key="resgate.id"
                >
                  <td>{{ resgate.ano }}</td>
                  <td>{{ resgate.evento }}</td>
                  <td align="right">{{ resgate.quantidade }}</td>
                  <td align="right">{{ formataValor(resgate.valor) }}</td>
                  <td align="right">{{ formataValor(calculaComissao(resgate.quantidade)) }}</td>
                  <td align="right">{{ formataValor(calculaTotal(resgate)) }}</td>
                  <td class="tdfora">
                    <div class="divfora">
                      <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                        <q-btn
                          v-if="!mapaResgates.visualizar"
                          color="red"
                          icon="delete"
                          @click="removeResgate(resgate.id)"
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr class="text-weight-bold">
                  <td colspan="2">Total Geral:</td>
                  <td align="right">{{ calculaValoresTotais().totalQuantidade }}</td>
                  <td align="right"></td>
                  <td align="right">{{ totais.totalComissao }}</td>
                  <td align="right">{{ totais.totalGeral }}</td>
                  <td></td>
                </tr>

              </tbody>
            </q-markup-table>
          </q-form>
        </q-card-section>

        <q-card-actions class="q-mb-sm" align="right">

          <q-input
            v-if="!mapaResgates.visualizar"
            dense
            clearable
            bottom-slots
            label="Data de vencimento"
            class="q-mr-md"
            :error="Boolean(errors.vencimento)"
            v-model="vencimento"
            @click="$refs.qDateVencimento.show()"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateVencimento"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    v-model="vencimento"
                    @input="() => $refs.qDateVencimento.hide()"
                    mask="DD/MM/YYYY"
                    :locale="langDate"
                    minimal
                    today-btn
                  />
                </q-popup-proxy>
              </q-icon>
            </template>
            <template v-slot:error>
              <span v-for="(error, i) in errors.vencimento" :key="error + i">{{
                error
              }}</span>
            </template>
          </q-input>

          <q-btn
            v-if="!mapaResgates.visualizar"
            @click="confirmarMapaResgates()"
            :loading="loadingFecharStatus"
            label="Gerar Mapa"
            color="positive"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>

          <q-btn
            v-if="mapaResgates.visualizar"
            @click="imprimirRelatorio"
            :loading="loadingRelatorio"
            label="Imprimir"
            color="positive"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import { Money } from "v-money";
import moment from "moment";

export default {
  name: "modal-mapa-resgates-novo",
  components: { Money },
  data() {
    return {
      anos: [],
      eventos: [],
      form: {
        ano: null,
        evento_obj: {},
        quantidade: 0,
        valor: 0.00,
        comissao: 0.00,
        total: 0.00,
      },
      vencimento: moment().add(1, "month").format("DD/MM/YYYY"),
      errors: {},
      totais: {},
      loadingAdicionarItem: false,
      loadingFecharStatus: false,
      loadingRelatorio: false,
      loadingEventos: false,
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked:false
      }
    };
  },
  methods: {
    pulaProx(value) {
      switch (value) {
        case "ano":
          this.$refs.anoRef.focus();
          this.$refs.anoRef.showPopup();
          break;
        case "evento":
          this.$refs.eventoRef.focus();
          this.$refs.eventoRef.showPopup();
          break;
        case "quantidade":
          setTimeout(() => {
            this.$refs.quantidadeRef.select();
          }, 10);
          break;
        case "valor":
          setTimeout(() => {
            this.$refs.valorRef.$el.focus();
          }, 10);
          break;
      }
    },
    cancela() {
      this.$store.commit("openDialogNovoMapaResgates", false);
    },
    calculaComissao(quantidade) {
      return quantidade * this.opcoes.maparesgates_valor_comissao;
    },
    calculaTotal(resgate) {
      const comissao = this.calculaComissao(resgate.quantidade);

      return parseFloat(resgate.quantidade) * parseFloat(resgate.valor) + parseFloat(comissao);
    },
    calculaValoresTotais() {
      let totalQuantidade = 0, totalValor = 0, totalComissao = 0, totalGeral = 0;

      this.mapaResgates.mapa_resgates_items.forEach(item => {
        totalQuantidade += item.quantidade;
        totalValor += item.valor;
        totalComissao += item.comissao;
        totalGeral += this.calculaTotal(item);
      });

      this.totais.totalQuantidade = totalQuantidade;
      this.totais.totalValor = this.formataValor(totalValor);
      this.totais.totalComissao = this.formataValor(totalComissao);
      this.totais.totalGeral = this.formataValor(totalGeral);

      return this.totais;
    },
    changeAnoEvento(ano) {
      this.form.evento_obj = {};
      this.loadEventosPorAno();
    },
    changeEvento(resgate) {
      this.form.valor = resgate.valor;
      this.atualizaTotal();
    },
    changeQuantidade() {
      this.atualizaTotal();
    },
    changeValor() {
      this.atualizaTotal();
    },
    atualizaTotal() {
      this.form.total = this.calculaTotal(this.form);
    },
    confirmarMapaResgates(){
      this.$q.dialog({
          title: 'Confirmar',
          message: `Confirma a geração de mapa de resgates`,
          cancel: true,
          persistent: true,
          html: true,
        }).onOk(() => {
          this.fecharStatusMapaResgate();
        });
    },
    fecharStatusMapaResgate() {
      if (this.mapaResgates.mapa_resgates_items.length === 0) {
          this.$q.notify({
            message: "Para fechar o mapa é necessário pelo menos um item",
            color: "red",
            position: "top-right",
          });
          return;
      }

      this.loadingFecharStatus = false;

      axios.post(
        'maparesgates/edit/',
        { id: this.mapaResgates.id, confirm, vencimento: moment(this.vencimento, 'DD/MM/YYYY').format('Y-MM-DD') },
      )
      .then(resp => {
        if (resp.data.errors) {
          throw resp.data;
        }

        this.$q.notify({
          message: "Sucesso ao fechar o status do mapa de resgate",
          color: "green",
          position: "top-right",
        });

        this.$store.dispatch("loadMapasResgates", { forcarCarregamento: true });

        this.loadingFecharStatus = false;
        this.cancela();
        this.imprimirRelatorio();
      })
      .catch(err => {
        this.loadingFecharStatus = false;

          this.$q.notify({
            message: "Houve um erro ao fechar o status do mapa de resgate",
            color: "red",
            position: "top-right",
          });

      });
    },
    imprimirRelatorio() {
      this.loadingRelatorio = true;

      axios
        .get("maparesgates/relatorio/" + this.mapaResgates.id, {
          headers: {
            Accept: "text/html",
          },
        })
        .then(res => {
          const windowJanela = window.open("about:blank");
          windowJanela.document.write(res.data);
          windowJanela.document.close();

          this.loadingRelatorio = false;
        })
        .catch(err => {
          this.$q.notify({
            message:"Não foi possível imprimir o relatório!",
            color: "red",
            position: "top-right"
          });
          this.loadingRelatorio = false;
        });
    },
    loadEventosPorAno() {
      this.loadingEventos = true;

      axios.get('/produtos?tipo=P&ano=' + this.form.ano)
        .then(resp => {
          this.eventos = resp.data.eventos;
          this.loadingEventos = false;
          this.pulaProx('evento');
        })
        .catch(err => {
          this.$q.notify({
            message: 'Houve um erro ao buscar os eventos',
            color: "red",
            position: "top-right"
          });
          this.loadingEventos = false;
          this.pulaProx('evento');
        });
    },
    formataValor(valor) {
      if (!valor) {
        valor = 0;
      }

      return "R$ " + valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    },
    adicionarResgate() {
      this.loadingAdicionarItem = true;

      const evento = this.form.evento_obj;
      this.errors = {};

      axios.post('maparesgates/add-item', {
        maparesgate_id: this.mapaResgates.id,
        ano: this.form.ano,
        evento: evento.nome,
        quantidade: this.form.quantidade,
        valor: this.form.valor,
      })
      .then(resp => {
        this.loadingAdicionarItem = false;

        if (!resp.data.success) {
          throw resp;
        }

        this.limparFormulario();
        this.$store.dispatch('loadMapaResgates', { id: this.mapaResgates.id });

        this.pulaProx('ano');
      })
      .catch(err => {
        this.$q.notify({
          message: 'Houve um erro ao adicionar resgate',
          color: "red",
          position: "top-right",
        });

        if (err.data && Object.keys(err.data.errors).length > 0) {
          this.errors = err.data.errors;
          const primeiroErro = Object.keys(this.errors)[0];

          this.pulaProx(primeiroErro);
        }

        this.loadingAdicionarItem = false;
      });
    },
    removeResgate(id) {
      this.$q.dialog({
        title: 'Cuidado',
        message: 'Deseja realmente excluir este resgate?'
      })
      .onOk(() => {

        axios.post('maparesgates/delete-item', { id })
          .then(resp => {
            this.$q.notify({
              message: 'Resgate excluído com sucesso',
              color: "green",
              position: "top-right",
            });
            this.$store.dispatch('loadMapaResgates', { id: this.mapaResgates.id });
          })
        .catch(err => {
          this.$q.notify({
            message: 'Houve um erro ao excluir o resgate',
            color: "red",
            position: "top-right"
          });
        });

      });
    },
    limparFormulario() {
      this.form = this.$options.data().form;
    },
    loadAnos() {
      let anos = [];
      let inicio = 1991;
      let d = new Date();
      let anoAtual = d.getFullYear();

      while (inicio < anoAtual) {
        anos.push(inicio);
        inicio++;
      }

      this.anos = anos;
    }
  },
  mounted() {
    this.loadAnos();
  },
  computed: {
    ...mapGetters([
      "openDialogNovoMapaResgates",
      "mapaResgates",
      "loadingDialogNovoMapaResgates",
      "opcoes",
    ]),
  },
  watch: {
    'form.ano': function (val) {
      if (val) {
        this.changeAnoEvento(val);
      }
    },
    'form.evento_obj': function (val) {
      this.changeEvento(val);
    },
    'form.quantidade': function (val) {
      this.changeQuantidade();
    },
    'form.valor': function (val) {
      this.changeValor();
    },
  },
};
</script>

<style lang="stylus" scoped>
#novo-mapa-resgate-card
  max-width: 100%
  width: 100%

  @media screen and (min-width: 768px)
    width: 70%

.resgate-titulos,
.resgate-form,
.resgate
  display: grid
  position: relative
  grid-template-columns: repeat(7, 1fr)
  border-bottom: 1px solid #ccc

  div
    display: flex
    align-items: center

  .div-fora-modal
    display: none;

  p
    margin-bottom: 0

.resgate-titulos:hover
.resgate-form:hover
.resgate:hover

  .div-fora-modal
    display: inline-block

.q-field--with-bottom
  padding-bottom: 0

td.form-error
  label.q-field--with-bottom
    padding-bottom: 24px

</style>

<style lang="stylus">
.mapa-resgates-valor-field .q-field__native.row {
  padding: 0
}
</style>
