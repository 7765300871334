<template>
  <q-page id="receitas">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!isPermissoReceitas"></sem-permissao>
        <div class="col-12" v-if="isPermissoReceitas">
          <q-table dense title="Receitas" :data="movcontas" :columns="columns" row-key="id" :loading="buscandoRec"
            :bottom="false" :pagination.sync="pagination" selection="multiple" :selected.sync="selected"
            :no-data-label="'Nenhum dado'" :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'" :visible-columns="visibleColumns">
            <template v-slot:bottom></template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td auto-width>
                  <q-checkbox v-model="props.selected" color="secondary" />
                </q-td>
                <q-td key="id" v-if="visibleColumns.indexOf('id') !== -1" class="text-right">
                  {{ props.row.id }}
                </q-td>
                <q-td key="cadastro">
                  <router-link style="text-decoration: none;color: #000" :to="props.row.cadastro
                      ? '/cadastros/' + props.row.cadastro_id
                      : ''
                    ">
                    {{
                      props.row.cadastro
                        ? props.row.cadastro.nome.length > 40
                          ? props.row.cadastro.nome.substr(0, 40) + "..."
                          : props.row.cadastro.nome
                        : "Sem Cadastro"
                    }}
                    <q-tooltip
                      v-if="props.row.cadastro && props.row.cadastro.nome && props.row.cadastro.nome.length > 40">
                      {{ props.row.cadastro.nome }}
                    </q-tooltip>
                  </router-link>
                </q-td>
                <q-td class="text-right" v-if="notasFiscaisInstalado && visibleColumns.indexOf('notas_fiscais') !== -1">
                  <div v-for="(notaFiscal, index) in props.row.notas_fiscais" :key="index">
                    <span v-if="[5, 7].includes(notaFiscal.status)"
                      :class="notaFiscal.status === 5 ? 'text-negative' : ''">
                      {{ notaFiscal.numero_nfse }}
                      <q-tooltip>
                        {{ statusNotasFiscais[notaFiscal.status] }} -
                        Registrado em {{ notaFiscal.dtemissao | formatDate }}
                      </q-tooltip>
                    </span>
                  </div>
                </q-td>
                <q-td key="telefone" v-if="visibleColumns.indexOf('telefone') !== -1" v-html="props.row.cadastro
                    ? breakLine(props.row.cadastro.telefone)
                    : ''
                  " class="text-right">
                </q-td>
                <q-td key="descricao" v-if="visibleColumns.indexOf('descricao') !== -1">
                  <template v-slot:default>
                    {{
                      props.row.descricao && props.row.descricao.length > 40
                        ? props.row.descricao.substr(0, 40) + "..."
                        : props.row.descricao
                    }}
                    <q-tooltip v-if="
                      props.row.descricao && props.row.descricao.length > 40
                    ">
                      {{ props.row.descricao }}
                    </q-tooltip>
                  </template>
                </q-td>
                <q-td key="gateway" v-if="visibleColumns.indexOf('gateway') !== -1">
                  <q-btn v-if="!props.row.nadaDaqui" :icon="'img:' + props.row.gateway.icon_url" size="13px" flat dense>
                  </q-btn>
                  <q-btn v-if="!props.row.nadaDaqui" :color="getColorBtnFatura(props.row)" size="13px" dense>
                    <template v-slot:default>
                      <q-tooltip :content-class="'bg-' + getColorBtnFatura(props.row)">
                        {{
                          mostraCobranca(props.row.fatura, props.row.gateway)
                        }}
                      </q-tooltip>
                    </template>
                  </q-btn>
                  <q-btn v-if="props.row.nadaDaqui" icon="money_off" dense flat size="13px">
                    <template v-slot:default>
                      <q-tooltip>Sem Cobrança</q-tooltip>
                    </template>
                  </q-btn>

                  <q-btn v-if="props.row.gateway && props.row.gateway.pode_enviar" flat dense size="13px"
                    icon="img:data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAAmJLR0QA/4ePzL8AAAA7SURBVDjLY2AYLuAow2Ew+R8ID6PwkXmHERpACiEkgsWAhTeqYVTDwGs4zHAITIIED6HwkXmHhktuBgBYmul3nH84agAAAABJRU5ErkJggg=="
                    style="margin-left: 5px" @click="linkBoleto(props.row.short_url)"></q-btn>
                </q-td>
                <q-td v-if="visibleColumns.indexOf('vencimento') !== -1" key="vencimento" class="text-center">
                  {{ new Date(props.row.vencimento) | formatDate }}
                </q-td>
                <q-td v-if="visibleColumns.indexOf('referencia') !== -1" key="referencia" class="text-center">
                  {{ props.row.referencia }}
                </q-td>
                <q-td v-if="visibleColumns.indexOf('valor') !== -1" key="valor" class="text-right">
                  R$ {{ props.row.valor | currency }}
                </q-td>
                <q-td v-if="visibleColumns.indexOf('valor_desconto') !== -1" key="valor_desconto" class="text-right">
                  {{ props.row.valor_desconto | currency | value(props.row.tipo_desconto) }}
                </q-td>
                <q-td v-if="visibleColumns.indexOf('valor_liquidado') !== -1" key="valor_liquidado" class="text-right"
                  :class="{
                    'text-red': props.row.valor_liquidado < props.row.valor,
                    'text-green': props.row.valor_liquidado > props.row.valor
                  }">
                  R$ {{ props.row.valor_liquidado | currency }}
                </q-td>
                <q-td v-if="visibleColumns.indexOf('liquidacao') !== -1" key="liquidacao" class="text-center">
                  {{ props.row.liquidacao | formatDate }}
                </q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn v-if="props.row.status !== 'Cancelado' && props.row.status !== 'Liquidado'"
                        :disable="props.row.gateway ? !props.row.gateway.pode_enviar : true"
                        @click="linkBoleto(props.row.short_url)" class="text-white"
                        icon="img:data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAAmJLR0QA/4ePzL8AAAA7SURBVDjLY2AYLuAow2Ew+R8ID6PwkXmHERpACiEkgsWAhTeqYVTDwGs4zHAITIIED6HwkXmHhktuBgBYmul3nH84agAAAABJRU5ErkJggg=="
                        size="12px">
                        <q-tooltip>Visualizar Cobrança</q-tooltip>
                      </q-btn>

                      <q-btn @click="linkDetalhe(props.row.short_url)" class="bg-white text-black" icon="receipt"
                        size="12px">
                        <q-tooltip>Visualizar Detalhamento Fatura</q-tooltip>
                      </q-btn>

                      <q-btn @click="detalhar(props.row)" size="12px" color="accent" icon="info">
                        <q-tooltip>Mais informações</q-tooltip>
                      </q-btn>

                      <q-btn v-if="
                        props.row.status !== 'Cancelado' &&
                        props.row.status !== 'Liquidado'
                      " @click="enviarEmail(props.row)" class="text-white" :class="{
                          'bg-secondary': props.row.gateway.pode_enviar,
                          'bg-grey-10': !props.row.gateway.pode_enviar
                        }" icon="mail" size="12px" :disable="!props.row.gateway.pode_enviar">
                        <q-tooltip>Enviar Por E-mail</q-tooltip>
                      </q-btn>

                      <q-btn v-if="
                        props.row.status !== 'Cancelado' &&
                        props.row.status !== 'Liquidado'
                      " @click="changeGateways(props.row)" color="secondary" icon="repeat" size="12px">
                        <q-tooltip>Alterar Forma de Pagamento</q-tooltip>
                      </q-btn>

                      <q-btn v-show="props.row.status !== 'Cancelado'" @click="edit(props.row)"
                        class="bg-secondary text-white" icon="edit" size="12px">
                        <q-tooltip>Editar</q-tooltip>
                      </q-btn>

                      <q-btn v-if="
                        props.row.status !== 'Cancelado' &&
                        !props.row.liquidado
                      " @click="liquidar(props.row)" color="positive" icon="attach_money" size="12px">
                        <q-tooltip>Liquidar</q-tooltip>
                      </q-btn>

                      <q-btn v-if="props.row.status === 'Liquidado'" @click="estornar(props.row)"
                        class="bg-secondary text-white" icon="reply" size="12px">
                        <q-tooltip>Não Pago</q-tooltip>
                      </q-btn>

                      <q-btn v-if="props.row.status === 'Cancelado'" @click="reativa(props.row)"
                        class="bg-secondary text-white" icon="reply" size="12px">
                        <q-tooltip>Reabrir</q-tooltip>
                      </q-btn>
                      
                      <q-btn v-if="props.row.status === 'Aberto'" @click="excluir(props.row)"
                        class="bg-negative text-white" icon="delete_forever" size="12px">
                        <q-tooltip>Cancelar</q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn @click="detalhar(props.row)" size="12px" class="bg-primary text-white" icon="info">
                      <q-tooltip>Mais informações</q-tooltip>
                    </q-btn>
                    <q-btn v-if="
                      props.row.status !== 'Cancelado' &&
                      props.row.status !== 'Liquidado'
                    " @click="changeGateways(props.row)" class="bg-teal text-white" icon="repeat" size="12px">
                      <q-tooltip>Alterar Forma de Pagamento</q-tooltip>
                    </q-btn>
                    <q-btn v-if="props.row.status !== 'Cancelado' && props.row.status !== 'Liquidado'"
                      :disable="props.row.gateway ? !props.row.gateway.pode_enviar : true"
                      @click="linkBoleto(props.row.short_url)" class="bg-white text-black"
                      icon="img:data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAAmJLR0QA/4ePzL8AAAA7SURBVDjLY2AYLuAow2Ew+R8ID6PwkXmHERpACiEkgsWAhTeqYVTDwGs4zHAITIIED6HwkXmHhktuBgBYmul3nH84agAAAABJRU5ErkJggg=="
                      size="12px">
                      <q-tooltip>Visualizar Cobrança</q-tooltip>
                    </q-btn>
                    <q-btn @click="linkDetalhe(props.row.short_url)" class="bg-white text-black" icon="receipt"
                      size="12px">
                      <q-tooltip>Visualizar Detalhamento Fatura</q-tooltip>
                    </q-btn>
                    <q-btn v-if="
                      props.row.status !== 'Cancelado' &&
                      props.row.status !== 'Liquidado'
                    " @click="enviarEmail(props.row)" class="text-white" :class="{
                        'bg-cyan': props.row.gateway.pode_enviar,
                        'bg-grey-10': !props.row.gateway.pode_enviar
                      }" icon="mail" size="12px" :disable="!props.row.gateway.pode_enviar">
                      <q-tooltip>Enviar Por E-mail</q-tooltip>
                    </q-btn>
                    <q-btn v-show="props.row.status !== 'Cancelado'" @click="edit(props.row)"
                      class="bg-secondary text-white" icon="edit" size="12px">
                      <q-tooltip>Editar</q-tooltip>
                    </q-btn>
                    <q-btn v-if="
                      props.row.status !== 'Cancelado' &&
                      !props.row.liquidado
                    " @click="liquidar(props.row)" class="bg-green-7 text-white" icon="attach_money" size="12px">
                      <q-tooltip>Liquidar</q-tooltip>
                    </q-btn>
                    <q-btn v-if="props.row.status === 'Liquidado'" @click="estornar(props.row)"
                      class="bg-secondary text-white" icon="reply" size="12px">
                      <q-tooltip>Não Pago</q-tooltip>
                    </q-btn>
                    <q-btn v-if="props.row.status === 'Cancelado'" @click="reativa(props.row)"
                      class="bg-secondary text-white" icon="reply" size="12px">
                      <q-tooltip>Reabrir</q-tooltip>
                    </q-btn>
                    <q-btn v-if="props.row.status === 'Aberto'" @click="excluir(props.row)"
                      class="bg-negative text-white" icon="delete_forever" size="12px">
                      <q-tooltip>Cancelar</q-tooltip>
                    </q-btn>
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template v-slot:top="props">
              <q-btn flat id="addReceitas" round dense :icon="'add'" class="q-ml-md text-primary"
                style="margin-right: 10px;margin-left: 0" @click="modal()" />
              <div class="col-1 q-table__title">Receita</div>
              <q-btn v-if="allReceita" flat dense no-wrap v-ripple color="secondary" no-caps :label="'Selecionar todas as ' + pagingReceitas.count + ' receitas'
                " class="q-ml-sm q-px-md" :class="{
                  'bg-white text-blue-7': !allReceitas,
                  'bg-green-7 text-white': allReceitas
                }" clickable @click="setAll" />
              <q-space />

              <q-btn v-if="$q.screen.gt.xs" v-show="filtrosDrp === 'Liquidado'" flat dense no-wrap v-ripple
                color="primary" no-caps :label="parseInt(filtrarPorLiquidacao) ? 'Liquidação' : 'Vencimento'"
                class="q-ml-sm q-px-md bg-white text-blue-7" style="margin-right: 10px">
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section class="text-uppercase text-grey-7" style="font-size: 0.7rem">Data
                        de</q-item-section>
                    </q-item>
                    <q-item :class="{ active_status: !Boolean(parseInt(filtrarPorLiquidacao)) }" clickable v-close-popup
                      aria-hidden="true" @click="filtrarPorLiquidacao = '0'">
                      <q-item-section>Vencimento</q-item-section>
                    </q-item>
                    <q-item :class="{ active_status: Boolean(parseInt(filtrarPorLiquidacao)) }" clickable v-close-popup
                      aria-hidden="true" @click="filtrarPorLiquidacao = '1'">
                      <q-item-section>Liquidação</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <date-picker></date-picker>
              <q-btn v-if="$q.screen.gt.xs" flat dense no-wrap v-ripple color="primary" no-caps :label="filtrosDrp"
                class="q-ml-sm q-px-md bg-white text-blue-7" style="margin-right: 10px">
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem">Status</q-item-section>
                    </q-item>
                    <q-item :class="{ active_status: filtrosDrp === key }" v-for="(key, val) in status" :key="key + val"
                      clickable v-close-popup aria-hidden="true" @click="changeFilterStatus(key, val)">
                      <q-item-section>{{ key }} | {{ record_count[val] }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-btn v-if="$q.screen.gt.xs" flat dense no-wrap v-ripple color="primary" no-caps :label="gatewaysDrp === 'Todos' ? 'Forma de Pagamento' : gatewaysDrp
                " class="q-ml-sm q-px-md bg-white text-blue-7" style="margin-right: 10px">
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section class="text-uppercase text-grey-7" style="font-size: 0.7rem">Forma de
                        Pagamento</q-item-section>
                    </q-item>
                    <q-item :class="{ active_status: gatewaysDrp === key.titulo }"
                      v-for="(key, val) in findGateways.gateways" :key="key + val" clickable v-close-popup
                      aria-hidden="true" @click="changeFilterGateways(key, val)">
                      <q-item-section>{{ key.titulo }}</q-item-section>
                    </q-item>
                    <q-item :class="{ active_status: gatewaysDrp === 'Todos' }" @click="changeFilterGatewaysS()"
                      v-close-popup clickable aria-hidden="true">
                      <q-item-section>Todos</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-btn flat dense no-caps no-wrap v-ripple color="primary" style="margin-right: 10px"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                :label="statusRemessaDrp === 'Todos' ? 'Status da Remessa' : statusRemessaDrp">
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section class="text-uppercase text-grey-7" style="font-size: 0.7rem">Status da
                        Remessa</q-item-section>
                    </q-item>
                    <q-item v-for="(value, key) in statusRemessaLista" :key="key" clickable v-close-popup
                      aria-hidden="true" :class="{ active_status: statusRemessaDrp === value }"
                      @click="changeFilterStatusRemessa(value, key)">
                      <q-item-section>{{ value }}</q-item-section>
                    </q-item>
                    <q-item :class="{ active_status: statusRemessaDrp === 'Todos' }"
                      @click="changeFilterStatusRemessaS()" v-close-popup clickable aria-hidden="true">
                      <q-item-section>Todos</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-select v-model="visibleColumns" multiple borderless dense options-dense :display-value="'Colunas'"
                emit-value map-options :options="columns" option-value="name" style="min-width: 100px" />

              <q-btn v-if="hasVideo" dense flat color="bg-primary text-blue-7" icon="ondemand_video" class="q-ml-md"
                @click="modalIntro">
                <template v-slot:default>
                  <q-tooltip>Introdução as Receitas</q-tooltip>
                </template>
              </q-btn>

              <q-btn v-if="$q.screen.gt.xs" flat dense rounded no-wrap v-ripple
                class="q-ml-sm q-px-md bg-white text-black" no-caps icon="more_vert" style="margin-right: 10px">
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section class="text-uppercase text-grey-7" style="font-size: 0.7rem">Mais
                        Opções</q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup aria-hidden="true" @click="listar()">
                      <q-item-section>Atualizar</q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup aria-hidden="true" @click="props.toggleFullscreen">
                      <q-item-section>{{
                        props.inFullscreen ? "Minimizar" : "Maximizar Tela"
                        }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>
          </q-table>
          <div class="col-12 col-md-12 text-center" v-if="buscandoRec">
            <br />
            <q-spinner color="blue-7" size="3em" />
          </div>
          <q-page-sticky position="top-right" :offset="[0, 90]">
            <q-btn :color="OpenOpcoesAvancadasReceitas ? 'red-7' : 'blue-7'"
              :icon="OpenOpcoesAvancadasReceitas ? 'close' : 'settings'" class="rotate-0"
              style="border-top-left-radius: 50px;border-bottom-left-radius: 50px" @click="
                OpenOpcoesAvancadasReceitas = !OpenOpcoesAvancadasReceitas
                ">
              <q-tooltip :content-class="OpenOpcoesAvancadasReceitas
                  ? 'bg-negative text-white text-center'
                  : 'bg-secondary text-white text-center'
                ">{{
                  OpenOpcoesAvancadasReceitas
                    ? "Fechar Opções Avançadas"
                    : "Opções Avançadas"
                }}</q-tooltip>
            </q-btn>
          </q-page-sticky>
          <q-drawer side="right" v-model="OpenOpcoesAvancadasReceitas" bordered :width="280" :breakpoint="500"
            behavior="default" content-class="bg-white">
            <q-scroll-area class="fit">
              <div class="q-pa-sm">
                <div class="q-table__title">Opções Avançadas</div>
                <div>
                  <q-list class="rounded-borders" style="max-width: 350px; margin-left: -10px;width: 300px;">
                    <q-item-label header></q-item-label>

                    <q-item clickable v-ripple @click.exact="abreArquivos">
                      <q-item-section>
                        <q-item-label lines="1">Arquivos</q-item-label>
                        <q-item-label caption>Visualizar arquivos de remessa e retorno</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="faturar">
                      <q-item-section avatar v-if="loadModalFaturamento">
                        <q-spinner color="blue-7" size="1.5em" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label lines="1">Faturar</q-item-label>
                        <q-item-label caption>Realiza o Faturamento das receitas</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="acaoMarcar">
                      <q-item-section>
                        <q-item-label lines="1">Marcar para Remessa</q-item-label>
                        <q-item-label caption>Prepara as faturas para registrar no banco</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="acaoDesmarcar">
                      <q-item-section>
                        <q-item-label lines="1">Desmarcar da Remessa</q-item-label>
                        <q-item-label caption>Desmarca as faturas do registro no banco</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="gerarRemessa">
                      <q-item-section>
                        <q-item-label lines="1">Gerar Remessa</q-item-label>
                        <q-item-label caption>Geração de remessa</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="gerarNota">
                      <q-item-section>
                        <q-item-label lines="1">Gerar Nota Fiscal</q-item-label>
                        <q-item-label caption>Geração de nota fiscal</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="acaoEmail">
                      <q-item-section>
                        <q-item-label lines="1">Enviar por E-mail</q-item-label>
                        <q-item-label caption>Envio de receitas por E-mail</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="acaoWhatsApp">
                      <q-item-section>
                        <q-item-label lines="1">Enviar por WhatsApp</q-item-label>
                        <q-item-label caption>Envio de receitas por WhatsApp</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="liquidarMassa">
                      <q-item-section>
                        <q-item-label lines="1">Liquidar em Massa</q-item-label>
                        <q-item-label caption>Liquidar em massa as receitas selecionadas</q-item-label>
                      </q-item-section>
                    </q-item>


                    <q-item clickable v-ripple @click.exact="receitasMassa">
                      <q-item-section>
                        <q-item-label lines="1">Inclusão em Massa</q-item-label>
                        <q-item-label caption>Opção para inclusão em massa</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="lancamentosMassa">
                      <q-item-section>
                        <q-item-label lines="1">Alterar Conta Bancária</q-item-label>
                        <q-item-label caption>Alteração da conta bancária da receita</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="planoDeContasMassa">
                      <q-item-section>
                        <q-item-label lines="1">Alterar Plano de Contas</q-item-label>
                        <q-item-label caption>Alteração do plano de contas</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="alterarVencimento">
                      <q-item-section>
                        <q-item-label lines="1">Alterar Vencimento em Massa</q-item-label>
                        <q-item-label caption>Alteração do vencimento das receitas em
                          massa</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="alterarReferencia">
                      <q-item-section>
                        <q-item-label lines="1">
                          Alterar Referência em Massa
                        </q-item-label>
                        <q-item-label caption>
                          Alteração da referência das receitas em massa
                        </q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="cancelarMassa">
                      <q-item-section>
                        <q-item-label lines="1">Cancelar em Massa</q-item-label>
                        <q-item-label caption>Cancelamento de receitas em massa</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click.exact="pushEmails">
                      <q-item-section>
                        <q-item-label lines="1">Histórico de E-mails</q-item-label>
                        <q-item-label caption>Histórico de E-mails enviados</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </div>
            </q-scroll-area>
          </q-drawer>
        </div>
      </div>
    </div>
    <modal-alterar-plano-contas />
    <modal-alterar-conta-bancaria />
    <modal-alterar-vencimento />
    <modal-alterar-referencia />
    <modal-cancelar-massa />
    <modal-liquidar-movconta tipo-conta="receita" />
    <modal-editar-movconta tipo-conta="receita" />
    <modal-detalhar-movconta />
    <modal-enviar-email />
    <modal-agendar-mensagem :isReceitasPage="true" />
    <modal-inclusao-massa />
    <modal-faturar />
    <modal-alterar-forma-pagamento ref="modalAlterarFormaPagamento" />
    <modal-liquidar-massa />

    <card-total-itens tipo="receitas" :total="totalReceitasCard" :selecionado="totalSelecionadoCard" />

  </q-page>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "receitas",
  meta: {
    title: "DSB | Receitas"
  },
  data() {
    return {
      arqOn: false,
      OpenOpcoesAvancadasReceitas: false,
      status: {
        aberto: "Aberto",
        liquidado: "Liquidado",
        cancelado: "Cancelado"
      },
      urlBoleto: "/financeiro/fatura",
      cadastro_id: null,
      cadastro_nome: null,
      visibleColumns: JSON.parse(localStorage.getItem("receitas__visibleColumns")) || [
        "cadastro",
        "id",
        "telefone",
        "descricao",
        "gateway",
        "vencimento",
        "referencia",
        "valor"
      ],
      pagination: {
        sortBy: "id",
        descending: true,
        page: 1,
        rowsPerPage: 0
      },
      allReceita: false,
      hasVideo: false
    };
  },
  watch: {
    selected(value) {
      if (value.length === 0) {
        this.allReceita = false;
        this.$store.commit("allReceitas", false);
        this.$store.commit("selectediesReceitas", []);
        this.$store.commit("selectedReceitasCadastros", []);
      } else {
        if (value.length >= 10) {
          this.allReceita = true;
        }

        let ids = [];
        let cadastro_ids = [];
        for (let i in value) {
          if (value.indexOf(value[i]) !== -1) {
            ids.push(value[i].id);
            cadastro_ids.push(value[i].cadastro_id);
          }
        }
        this.$store.commit("selectediesReceitas", {
          selectediesReceitas: ids,
        });
        this.$store.commit("selectedReceitasCadastros", {
          selectedReceitasCadastros: cadastro_ids,
        });
      }
    },
    visibleColumns() {
      localStorage.setItem("receitas__visibleColumns", JSON.stringify(this.visibleColumns));
    },
  },
  methods: {
    planoDeContasMassa() {
      // this.$store.dispatch("getSelected");
      this.$store.dispatch(
        "planoContas",
        this.$route.path === "/receitas" ? "C" : "D"
      );
      this.$store.commit("OpenDialogAlterarPlanoDeContas", true);
      // $('#alterarPlanoDeContas').modal('open')
    },
    alterarVencimento() {
      this.$store.commit("OpenDialogAlterarVencimento", true);
    },
    alterarReferencia() {
      this.$store.commit("OpenDialogAlterarReferencia", true);
    },
    liquidarMassa() {
      this.$store.dispatch("openModalLiquidarMassa", true);
    },
    lancamentosMassa() {
      // this.$store.dispatch("getSelected");
      this.$store.dispatch("contas");
      this.$store.commit("OpenDialogAlterarContaBancaria", true);
    },
    cancelarMassa() {
      this.$store.dispatch("cancelarMassa");
      this.$store.commit("OpenDialogCancelarConfirmarDespesas", true);
      // $('#cancelarConfirmar').modal('open')
    },
    gerarRemessa() {
      this.$store.dispatch("gerarRemessa");
    },
    acaoMarcar() {
      this.$store.dispatch("acaoMarcarReceitas");
    },
    acaoDesmarcar() {
      this.$store.dispatch("acaoDesmarcarReceitas");
    },
    acaoEmail() {
      this.$store.dispatch("acaoEmailReceitas");
    },
    acaoWhatsApp() {
      this.$store.dispatch("acaoWhatsAppReceitas");
    },
    receitasMassa() {
      this.$store.dispatch("contas");
      this.$store.dispatch("acaoReceitasMassa");
    },
    pushEmails() {
      // $('#SettingReceitas').sidenav('close')
      this.$router.push("/historicoemails");
    },
    abreArquivos() {
      this.$router.push("/arquivos");
    },
    faturar() {
      this.$store.dispatch("faturar");
    },
    listar() {
      this.$store.dispatch("filtrosReceitas");
    },
    setAll() {
      this.$store.dispatch("setAll");
    },
    enviarEmail(value) {
      this.$store.dispatch("enviarEmailReceitas", value);
    },
    loadmore() {
      this.$store.dispatch("loadmoreReceias");
    },
    marcaItem(row, val) {
      this.$store.dispatch("marcaItemReceitas", { row: row, val: val });
    },
    getUrlPerfil(movconta) {
      return "/cadastros/" + movconta.cadastro_id;
    },
    changeFilterStatus(status, valor) {
      // limpar checkbox selecionados
      this.$store.dispatch("clearCheckbox");

      this.$store.dispatch("changeFilterStatus", {
        status: status,
        valor: valor
      });

      switch (status) {
        case "Liquidado":
          if (this.visibleColumns.indexOf("valor_liquidado") == -1) {
            this.visibleColumns.push("valor_liquidado");
          }
          if (this.visibleColumns.indexOf("liquidacao") == -1) {
            this.visibleColumns.push("liquidacao");
          }
          if (this.visibleColumns.indexOf("gateway") >= 0) {
            this.visibleColumns.splice(
              this.visibleColumns.indexOf("gateway"),
              1
            );
          }
          break;
        case "Aberto":
          if (this.visibleColumns.indexOf("valor_liquidado") >= 0) {
            this.visibleColumns.splice(
              this.visibleColumns.indexOf("valor_liquidado"),
              1
            );
          }
          if (this.visibleColumns.indexOf("liquidacao") >= 0) {
            this.visibleColumns.splice(
              this.visibleColumns.indexOf("liquidacao"),
              1
            );
          }
          if (this.visibleColumns.indexOf("gateway") == -1) {
            this.visibleColumns.push("gateway");
          }
          break;
        case "Cancelado":
          if (this.visibleColumns.indexOf("valor_liquidado") == -1) {
            this.visibleColumns.push("valor_liquidado");
          }
          if (this.visibleColumns.indexOf("liquidacao") == -1) {
            this.visibleColumns.push("liquidacao");
          }
          if (this.visibleColumns.indexOf("gateway") >= 0) {
            this.visibleColumns.splice(
              this.visibleColumns.indexOf("gateway"),
              1
            );
          }
      }
    },
    changeFilterGateways(status, valor) {
      let userData = {
        status: status,
        valor: valor
      };
      this.$store.dispatch("changeFilterGateways", userData);
    },
    changeFilterGatewaysS() {
      this.$store.dispatch("changeFilterGateways");
      // limpar checkbox selecionados
      this.$store.dispatch("clearCheckbox");
    },
    changeFilterStatusRemessa(status, valor) {
      let userData = {
        status: status,
        valor: valor,
      };
      this.$store.dispatch('changeFilterStatusRemessa', userData);
    },
    changeFilterStatusRemessaS() {
      this.$store.dispatch('changeFilterStatusRemessa');
      // limpar checkbox selecionados
      this.$store.dispatch('clearCheckbox');
    },
    handleScroll() {
      if (
        window.scrollY + 10 >=
        document.body.clientHeight - window.innerHeight
      ) {
        this.$store.dispatch("listarReceitas");
      }
    },
    marcarDesmarcarTodos() {
      this.$store.dispatch("marcarDesmarcarTodosReceitas");
    },
    linkBoleto(shortUrl) {
      window.open(this.urlGeral + shortUrl, "_blank");
    },
    linkDetalhe(shortUrl) {
      window.open(this.urlDetalhe + shortUrl, "_blank");
    },
    getUrlCadastro() {
      return "/cadastros/view/" + localStorage.getItem("id_cad");
    },
    excluir(movconta) {
      this.$store.dispatch("excluirReceitas", movconta);
    },
    reativa(movconta) {
      this.$store.dispatch("reativaReceitas", movconta);
    },
    estornar(movconta) {
      this.$store.dispatch("estornarReceitas", movconta);
    },
    detalhar(movconta) {
      this.$store.commit('receitaQrcode', movconta.id);
      this.$store.dispatch("detalharReceitas", movconta);
    },
    getTimeline() {
      this.$store.dispatch("getTimelineReceitas");
    },
    breakLine(value) {
      if (!value) return "";
      return value.replace(/,/g, "<br>").replace("/", "<br>");
    },
    separaLinha(value) {
      if (!value) return "";
      return value
        .replace(/,/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
        .replace("/", "&nbsp;&nbsp;&nbsp;&nbsp;");
    },
    modal() {
      this.$store.dispatch("modalReceitas");
      this.$store.dispatch("contas");
    },
    edit(movconta) {
      this.$store.dispatch("editarReceitas", JSON.parse(JSON.stringify(movconta)));
    },
    liquidar(movconta) {
      this.$store.dispatch("liquidarReceitas", movconta);
    },
    changeGateways(movconta) {
      this.$store.dispatch("changeGateways", movconta);
      this.$refs.modalAlterarFormaPagamento.inicializar();
    },
    statusRemessa(fatura) {
      if (!fatura) return "";
      let cl = "";
      let nome = "";
      switch (fatura.status_remessa) {
        case 0:
          nome = "Sem Registro";
          break;
        case 1:
          nome = "Marcado para Remessa";
          break;
        case 2:
          nome = "Remessa Gerada";
          break;
        case 3:
          nome = "Registrado";
          cl = "blue white-text";
          break;
        case 4:
          nome = fatura.status_retorno;
          cl = "red white-text";
          break;
        case 5:
          nome = fatura.status_retorno;
          cl = "white black-text";
          break;
        case 6:
          nome = fatura.status_retorno;
          cl = "orange white-text";
          break;
        case 7:
          nome = fatura.status_retorno;
          cl = "purple white-text";
          break;
        case 8:
          nome = fatura.status_retorno;
          cl = "badge purple lighten-1 white-text";
          break;
      }
      return '<span class="badge ' + cl + '">' + nome + "</span>";
    },
    ajustaRemessa(value) {
      let tam = $("#remessa" + value).width();
      $("#remessa" + value).css({
        "margin-left": -(tam / 2)
      });
    },
    mostraCobranca(value, gateway) {
      if (value.status_remessa === 0) {
        return gateway.titulo + " - Sem Registro";
      } else if (value.status_remessa === 1) {
        return gateway.titulo + " - Marcado para Remessa";
      } else if (value.status_remessa === 2) {
        return gateway.titulo + " - Remessa Gerada";
      } else if (value.status_remessa === 3) {
        return gateway.titulo + " - Registrado";
      } else if (value.status_remessa === 4) {
        return gateway.titulo + " - " + value.status_retorno;
      } else if (value.status_remessa === 5) {
        return gateway.titulo + " - " + value.status_retorno;
      } else if (value.status_remessa === 6) {
        return gateway.titulo + " - " + value.status_retorno;
      } else if (value.status_remessa === 7) {
        return gateway.titulo + " - " + value.status_retorno;
      } else if (value.status_remessa === 8) {
        return gateway.titulo + " - " + value.status_retorno;
      }
    },
    getColorBtnFatura(row) {
      if (!row.fatura) return "";
      let cl = "";
      let cor = "";
      //  * Em aberto - Sem Registro                                        - 0 - Cinza
      //  * Em aberto - Marcado para Remessa                                - 1 - Cinza Azul
      //  * Em aberto - Remessa Gerada                                      - 2 - Cinaza Azul Escuro
      //  * Em aberto - Registro OK - tipo '02'                             - 3 - Verde
      //  * Em aberto - Erro de Registro - tipo '03'                        - 4 - Vermelho
      //  * Liquidado - '06'                                                - 5 - Indigo
      //  * Baixado   - Fim do prazo pagamento '09'                         - 6 - Laranja
      //  * Protesto  - Fim Prazo pagamento protesto automático '23', '25'  - 7 - Roxo
      //  * Outros    - Diferente de '02', '03', '06', '09', '23', '25'     - 8 - Roxo Escuro

      switch (row.fatura.status_remessa) {
        case 0:
          cor = "blue-grey-3";
          break;
        case 1:
          cor = "blue-grey-6";
          break;
        case 2:
          cor = "blue-grey-9";
          break;
        case 3:
          cor = "green-7";
          break;
        case 4:
          cor = "red-7";
          break;
        case 5:
          cor = "indigo-7";
          break;
        case 6:
          cor = "orange-7";
          break;
        case 7:
          cor = "purple-7";
          break;
        case 8:
          cor = "purple";
          break;
      }
      return cor;
    },
    gerarNota() {
      this.$store.dispatch("gerarNota");
    },
    resetFiltrosReceitas() {
      this.$store.commit("filtrosReceitas", {
        ...JSON.parse(JSON.stringify(this.filtrosReceitas)),
        cadastro_id: null,
        movconta_id: null,
        busca: "",
      });
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "receitas");

      if (!this.videosManuais.video_receitas) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "receitas");
    }
  },
  computed: {
    ...mapGetters([
      "selected",
      "buscandoItems",
      "pagingReceitas",
      "buscandoRec",
      "filtrosReceitas",
      "movcontas",
      "record_count",
      "totalReceitas",
      "allReceitas",
      "urlGeral",
      "urlDetalhe",
      "aguardeEmailRec",
      "contadorReceitas",
      "counterReceitas",
      "acaoReceitas",
      "selectediesReceitas",
      "selectedReceitasCadastros",
      "realizandoAcaoReceitas",
      "loadModalFaturamento",
      "loadFaturamento",
      "faturamento",
      "avl",
      "movconta",
      "movcontaa",
      "errorsReceitas",
      "pulseRapido",
      "pulseCount",
      "marcado",
      "marquinhos",
      "mTodos",
      "loadLiquidar",
      "movPraDet",
      "movimentacao",
      "gerandoRemessa",
      "gatewaysDrp",
      'statusRemessaDrp',
      "btnDisabled",
      "filtrosDrp",
      "dataUser",
      "dataEmpresa",
      "findGateways",
      "isPermissoReceitas",
      "metadadosReceitas",
      "buscarPorLiquidacaoReceitas",
      "statusRemessaLista",
      "notasFiscaisInstalado",
      "receitaQrcode",
      "videosManuais",
      "manuaisDisponiveis"
    ]),
    selected: {
      get() {
        return this.$store.getters.selected;
      },
      set(value) {
        this.$store.commit("selected", value);
      }
    },
    totalReceitasCard() {
      const total = {
        valor: this.metadadosReceitas.valor_total || 0,
        quantidade: this.pagingReceitas.count || 0,
      };

      return total;
    },
    totalSelecionadoCard() {
      const selecionado = {
        valor: 0.00,
        quantidade: 0,
      };

      if (this.allReceitas) {
        selecionado.valor = this.metadadosReceitas.valor_total || 0;
        selecionado.quantidade = this.pagingReceitas.count || 0;

        return selecionado;
      }

      const campoValor = this.filtrosDrp === "Liquidado" ? "valor_liquidado" : "valor";

      selecionado.valor = this.selected.reduce((total, receita) => total + receita[campoValor], 0.00);
      selecionado.quantidade = this.selected.length;

      return selecionado;
    },
    filtrarPorLiquidacao: {
      get() {
        return String(this.buscarPorLiquidacaoReceitas);
      },
      set(value) {
        this.$store.commit("buscarPorLiquidacaoReceitas", value);
        this.$store.dispatch("resetPagingReceitas");
        this.$store.dispatch("listarReceitas");
      },
    },
    statusNotasFiscais() {
      return {
        0: 'Sem Registro',
        1: 'Marcado para Remessa',
        2: 'Remessa Enviada',
        3: 'Enviando',
        4: 'Aguardando Registro',
        5: 'Cancelado',
        6: 'Erro',
        7: 'Registrado',
      };
    },
    columns() {
      let columns = [
        {
          name: "id",
          label: "ID",
          align: "right",
          field: row => row.id,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "cadastro",
          required: true,
          align: "left",
          label: "Cadastro",
          field: row => (row.cadastro ? row.cadastro.nome : null),
          sortable: true
        },
        {
          name: "notas_fiscais",
          align: "center",
          label: "Notas Fiscais",
          field: row => row.notas_fiscais,
          sortable: false,
        },
        {
          name: "telefone",
          align: "center",
          label: "Telefone",
          field: row => row.telefone,
          sortable: true
        },
        {
          name: "descricao",
          align: "left",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true
        },
        {
          name: "gateway",
          align: "left",
          label: "Cobrança",
          field: row => (row.gateway ? row.gateway.titulo : null),
          sortable: true
        },
        {
          name: "vencimento",
          align: "center",
          label: "Vencimento",
          field: row => row.vencimento,
          sortable: true
        },
        {
          name: "referencia",
          align: "center",
          label: "Referência",
          field: row => row.referencia,
          sortable: true
        },
        {
          name: "valor",
          align: "center",
          label: "Valor",
          field: row => row.valor,
          sortable: true
        },

        {
          name: "valor_desconto",
          align: "center",
          label: "Valor Desconto",
          field: row => row.valor_desconto,
          sortable: true
        },
        {
          name: "valor_liquidado",
          align: "center",
          label: "Valor Liquidado",
          field: row => row.valor_liquidado,
          sortable: true
        },
        {
          name: "liquidacao",
          align: "center",
          label: "Liquidado em",
          field: row => row.liquidacao,
          sortable: true
        }
      ];

      if (!this.notasFiscaisInstalado) {
        columns = columns.filter(column => column.name !== 'notas_fiscais');
      }

      return columns;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.resetFiltrosReceitas();
    this.verificarPrimeiroVideo();
    this.$store.dispatch("resetPagingReceitas");
    this.$store.dispatch("listarReceitas");
    this.$store.dispatch("findGateways");
  },
  beforeDestroy() {
    this.$store.dispatch("clearCheckbox");
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("setLoadFiltrosQueryReceitas", false);
    this.$store.commit("allReceitas", false);
    window.removeEventListener("scroll", this.handleScroll);
    next();
  }
};
</script>
