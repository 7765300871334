import { render, staticRenderFns } from "./grafico-acessos-licencas.vue?vue&type=template&id=a891c4ce&scoped=true&"
import script from "./grafico-acessos-licencas.vue?vue&type=script&lang=js&"
export * from "./grafico-acessos-licencas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a891c4ce",
  null
  
)

export default component.exports