<template>
  <div id="cardGrafReceita">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            Receitas Liquidadas por forma de pagamento
          </div>
        </div>


        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section class="q-py-none">
      <PieChart v-if="exibirGrafico" :series="fetchUser.db.receitasFormaPagamento.series"
        :options="fetchUser.db.receitasFormaPagamento.options" :height="280" />
      <p v-else class="text-center q-mt-lg">Nenhuma receita utilizando forma de pagamento</p>
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PieChart from "../Graficos/pie-chart.vue";
export default {
  name: "card-receitas-forma-pagamento",
  components: {
    PieChart
  },
  data() { },
  methods: {
    atualizaDash() {
      this.$store.dispatch("fetchUser");
    },
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "16",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser", "layout"]),
    exibirGrafico() {
      if (this.fetchUser.db.receitasFormaPagamento.series.length <= 1) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="stylus" scoped></style>
