<template>
  <q-page id="whatsapp">
    <div class="q-pa-md">
      <div class="q-col-gutter-md row">
        <sem-permissao v-if="!hasWhatsApp"></sem-permissao>
        <div class="col-12 col-md-12" v-else>
          <q-table
            :loading="loadingWppMessagesList"
            loading-label="Carregando dados"
            :data="wppMessagesList"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :pagination.sync="pagination"
            :columns="columns"
            :bottom="false"
            row-key="id"
            selection="multiple"
            :selected.sync="selected"
          >
            <template v-slot:bottom>
              <div></div>
            </template>
            <template slot="body" slot-scope="props" :props="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td auto-width>
                  <q-checkbox v-model="props.selected" color="secondary" />
                </q-td>

                <q-td
                  key="cadastro_nome"
                  class="text-left"
                >
                  <router-link :to="'/cadastros/' + props.row.cadastro_id" style="text-decoration: none;color: #000" >{{ props.row.cadastro_nome }}</router-link>
                </q-td>

                <q-td
                  key="destinatario"
                  class="text-left"
                >{{ props.row.numero }}</q-td>

                <q-td
                  key="mensagemPronta"
                  class="text-left"
                >
                  {{props.row.mensagemPronta && props.row.mensagemPronta.length > 40 ? props.row.mensagemPronta.substr(0, 40) + '...' : props.row.mensagemPronta}}
                  <q-tooltip v-if="props.row.mensagemPronta && props.row.mensagemPronta.length > 40" content-style="font-size: 14px">
                    {{props.row.mensagemPronta}}
                  </q-tooltip>
                </q-td>

                <q-td
                  key="erro"
                  class="text-left"
                >
                  {{props.row.erro && props.row.erro.length > 40 ? props.row.erro.substr(0, 40) + '...' : props.row.erro}}
                  <q-tooltip v-if="props.row.erro && props.row.erro.length > 40" content-style="font-size: 14px">
                    {{props.row.erro}}
                  </q-tooltip>
                </q-td>

                <q-td
                  key="modified"
                  class="text-center"
                >{{ new Date(props.row.modified).toLocaleString('pt-BR') }}</q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div class="divdentro q-gutter-sm" style="margin-right: 10px">
                      <q-btn
                        @click="abrirModalDeletarFila(props.row)"
                        class="bg-negative text-white"
                        icon="delete"
                        size="12px"
                      ></q-btn>
                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      @click="abrirModalDeletarFila(props.row)"
                      class="bg-negative text-white"
                      icon="delete"
                      size="12px"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>
            <template  v-slot:top>
              <q-btn
                flat
                round
                dense
                :loading="deletandoFila"
                :icon="'add'"
                class="q-ml-md text-primary"
                style="margin-right: 10px;margin-left: 0"
                @click="openAgendarMensagem"
              />
              <div class="q-table__title">WhatsApp</div>

              <q-badge v-if="colorWhatsappStatus !== null" class="q-ml-sm q-mb-md" rounded :color="colorWhatsappStatus">
                <q-tooltip >
                {{ tipoWhatsappStatus }}
                </q-tooltip>
              </q-badge>

              <q-space/>

              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                no-wrap
                v-ripple
                color="primary"
                no-caps
                :label="status.label"
                class="q-ml-sm q-px-md bg-white text-blue-7"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                      >Status</q-item-section>
                    </q-item>
                    <q-item
                      :class="{'active_status': status.index === menu.index}"
                      v-for="menu in menuStatus"
                      :key="menu.index"
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="setStatus(menu)"
                    >
                      <q-item-section>{{ menu.label }}</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>

              <q-btn
                  v-if="hasVideo"
                  dense
                  flat
                  color="bg-primary text-blue-7"
                  icon="ondemand_video"
                  @click="modalIntro"
                >
                  <template v-slot:default>
                    <q-tooltip>Introdução ao WhatsApp</q-tooltip>
                  </template>
                </q-btn>
            </template>
          </q-table>
          <q-page-sticky position="top-right" :offset="[0, 90]">
            <q-btn
              :color="drawerRight ? 'red-7' : 'blue-7'"
              :icon="drawerRight ? 'close' : 'settings'"
              class="rotate-0"
              style="border-top-left-radius: 50px;border-bottom-left-radius: 50px"
              @click="drawerRight = !drawerRight"
              >
              <q-tooltip
                :content-class="drawerRight ? 'bg-negative text-white text-center' : 'bg-secondary text-white text-center'"
              >{{drawerRight ? 'Fechar Opções Avançadas' : 'Opções Avançadas'}}</q-tooltip>
            </q-btn>
          </q-page-sticky>
          <q-drawer
            side="right"
            v-model="drawerRight"
            bordered
            :width="280"
            :breakpoint="500"
            behavior="default"
            content-class="bg-white"
            >
            <q-scroll-area class="fit">
              <div class="q-pa-sm">
                <div class="q-table__title">
                  Opções Avançadas
                </div>
                <div>
                  <q-list
                    class="rounded-borders"
                    style="max-width: 350px; margin-left: -10px;width: 300px;"
                    >
                    <q-item-label header></q-item-label>

                    <!-- <q-item clickable v-ripple to="/arquivos">
                      <q-item-section>
                        <q-item-label lines="1">Arquivos</q-item-label>
                        <q-item-label caption>Visualizar os arquivos</q-item-label>
                      </q-item-section>
                    </q-item> -->

                    <q-item clickable v-ripple @click="openEditConfig">
                      <q-item-section>
                        <q-item-label lines="1">Configurações</q-item-label>
                        <q-item-label caption>Configurar envio das mensagens por WhatsApp</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item clickable v-ripple @click="openCancelarFilas">
                      <q-item-section>
                        <q-item-label lines="1">Cancelar Filas</q-item-label>
                        <q-item-label caption>Cancela todas as filas de mensagens selecionadas</q-item-label>
                      </q-item-section>
                    </q-item>

                  </q-list>
                </div>
              </div>
            </q-scroll-area>
          </q-drawer>
        </div>
      </div>
    </div>
    <modal-editar-settings />
    <modal-agendar-mensagem :isWhatsPage="true" />
    <modal-cancelar-filas />
  </q-page>
</template>

<script>
import axios from "@/axios-auth";
import { mapGetters } from "vuex";
import { Dialog, Notify } from 'quasar';
export default {
  meta: {
    title: "DSB | WhatsApp"
  },
  data() {
    return {
      drawerRight: false,
      deletandoFila: false,
      downloading: false,
      selected: [],
      menuStatus: [
        {
          label: "Na Fila",
          index: 0,
        },
        {
          label: "Enviando",
          index: 1,
        },
        {
          label: "Enviado",
          index: 2,
        },
        {
          label: "Erro",
          index: 3,
        },
        {
          label: "Cancelado",
          index: 4,
        },
      ],
      columns: [
        {
          name: "cadastro_nome",
          align: "left",
          label: "Cadastro",
          field: "cadastro_nome",
          sortable: true
        },
        {
          name: "destinatario",
          align: "left",
          label: "Destino",
          field: "destinatario",
          sortable: true
        },
        {
          name: "mensagem",
          label: "Mensagem",
          align: "left",
          field: "mensagemPronta",
          sortable: true
        },
        {
          name: "erro",
          align: "left",
          label: "Status",
          field: "erro",
          sortable: true
        },
        {
          name: "modified",
          align: "center",
          label: "Modificado em",
          field: "modified",
          sortable: true
        },
        {
          name: "tdfora",
          label: "",
        }
      ],
      pagination: {
        sortBy: "created_at",
        descending: false,
        page: 1,
        rowsPerPage: 0,
      },
      hasVideo: false
    };
  },
  computed: {
    ...mapGetters([
      "hasWhatsApp",
      "wppMessagesList",
      "status",
      "loadingWppMessagesList",
      "filtrosWhatsapp",
      "videosManuais",
      "manuaisDisponiveis",
      "tipoWhatsapp",
      "conexao"
    ]),
    colorWhatsappStatus(){
      switch (this.conexao) {
        case "conectado":
          return 'green';
        case "":
          return 'yellow-8';
        case "desconectado":
          return 'red-10';
        default:
          return 'yellow-8';
      }
    },
    tipoWhatsappStatus(){
      switch(this.tipoWhatsapp){
        case "dourasoftService":
          return 'Dourasoft Service';
        case "dourasoft":
          return 'Dourasoft';
        case "twilio":
          return 'Twilio';
        case "botconversa":
          return 'BotConversa';
        case "dtnetwork":
          return 'DT Network';
        case "digisac":
          return 'Digisac';
        default:
          return 'Sem parceiro de integração ativo';
      }
    }
  },
  methods: {
    openEditConfig() {
      this.$store.commit("OpenDialogEditarSettings", true);
    },
    openAgendarMensagem() {
      this.$store.commit("OpenDialogAgendarMensagem", true);
    },
    openCancelarFilas() {
      if (this.selected.length > 0) {
        this.$store.dispatch("abrirDialogCancelarFilas", this.selected);
        return;
      }

      Notify.create({
        color: 'orange',
        message: "Selecione pelo menos uma fila para cancelar",
        position: 'top-right',
      });
    },
    handleScroll() {
      if (window.scrollY === document.body.clientHeight - window.innerHeight) {
        this.$store.dispatch("listarWppMessagesList");
      }
    },
    abrirModalDeletarFila(fila) {
      Dialog.create({
      title: "Tem certeza que deseja excluir esta fila de mensagem",
      html: true,
      cancel: "Cancelar",
      persistent: true
    })
      .onOk(() => {
        this.deletarFila(fila);
      })
      .onCancel(() => {});
    },
    deletarFila(fila) {
      this.deletandoFila = true;

      axios
        .post(`/whatsapp/delete/${fila.id}`)
        .then(res => {
          if (!res.data.success) {
            throw res;
          }

          this.deletandoFila = false;

          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });

          this.$store.dispatch("listarWppMessagesList", { atualizar: true });
        })
        .catch(err => {
          this.deletandoFila = false;

          let mensagem = "Houve um erro ao salvar as filas, tente novamente!";

          if (err.data) {
            mensagem = err.data.message;
          }

          Notify.create({
            message: mensagem,
            color: "red",
            position: "top-right"
          });
        });
    },
    setStatus(value) {
      if (this.status === value) return;
      this.$store.commit("setStatus", value);
      this.$store.dispatch("listarWppMessagesList", { atualizar: true });
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "whatsapp");

      if (!this.videosManuais.video_whatsapp) {
        this.modalIntro();
      }
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "whatsapp");
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.$store.dispatch("listarWppMessagesList", { atualizar: true });
    this.verificarPrimeiroVideo();
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    next();
  },
};
</script>

<style lang="stylus">
#whatsapp {
  & table.q-table th, .q-table td {
    padding: 6px;
  }
}
</style>
