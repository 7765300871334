<template>
  <div id="modalEditarSettings">
    <q-dialog v-model="OpenDialogEditarSettings" persistent>
      <q-card style="width:600px">
        <q-card-section class="row items-center no-wrap">
          <div class="text-h6">Editar Configurações</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup @click="closeModal()" />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-pa-md">
            <div class="q-col-gutter-md row">
              <div class="col-12 q-mb-sm">
                <q-select
                  map-options
                  emit-value
                  v-model="config.whatsapps_tipo"
                  :options="tipos"
                  label="Parceiro de Integração"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">Sem Resultados</q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="col-12 col-md-6">
                <q-input
                  v-model="config.whatsapps_inicio"
                  label="Limitar o envio nesse período do dia"
                  mask="time"
                  :rules="['time']"
                >
                  <template v-slot:append>
                    <q-icon name="access_time" class="cursor-pointer">
                      <q-popup-proxy
                        ref="whatsappInicioRef"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-time v-model="config.whatsapps_inicio" @input="closeTimeDialog" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col-12 col-md-6">
                <q-input v-model="config.whatsapps_termina"
                  mask="time"
                  :rules="['time']">
                  <template v-slot:append>
                    <q-icon name="access_time" class="cursor-pointer">
                      <q-popup-proxy
                        ref="whatsappTerminaRef"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-time v-model="config.whatsapps_termina" @input="closeTimeDialog" />
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>
              <div class="col-12" v-if="config.whatsapps_tipo !== 'twilio' && config.whatsapps_tipo != 'dourasoft' && config.whatsapps_tipo != 'dourasoftService'" >
                <q-input
                  v-model="config.whatsapps_url"
                  label="URL"
                >
                </q-input>
              </div>
              <div class="col-12 q-mt-none" v-if="config.whatsapps_tipo != 'dourasoft' && config.whatsapps_tipo != 'dourasoftService'" >
                <q-input
                  v-model="config.whatsapps_token"
                  label="Token"
                >
                </q-input>
              </div>
              <div class="col-12" v-if="config.whatsapps_tipo == 'digisac'">
                <q-input
                  v-model="config.whatsapps_time"
                  label="Tempo para fechamento da chamada"
                  hint="minutos"
                >
                </q-input>
              </div>
              <div class="col-12" v-if="config.whatsapps_tipo == 'digisac'">
                <q-input
                  v-model="config.whatsapps_topicId"
                  label="ID do Assunto do Chamado"
                >
                </q-input>
              </div>
              <div class="col-12" v-if="config.whatsapps_tipo != 'dourasoft' && config.whatsapps_tipo != 'dourasoftService'" >
                <q-input
                  v-model="config.whatsapps_departmentId"
                  label="ID do departamento"
                >
                </q-input>
              </div>
              <div class="col-12" v-if="config.whatsapps_tipo == 'digisac'">
                <q-input
                  v-model="config.whatsapps_serviceId"
                  label="ID do serviço"
                >
                </q-input>
              </div>
              <div class="col-12" v-if="config.whatsapps_tipo != 'dourasoft' && config.whatsapps_tipo != 'dourasoftService'" >
                <q-input
                  v-model="config.whatsapps_userId"
                  label="ID do usuário"
                >
                </q-input>
              </div>
              <div class="col-12" v-if="config.whatsapps_tipo == 'twilio'">
                <q-input
                  v-model="config.whatsapps_from_number"
                  label="Número de telefone de envio"
                >
                </q-input>
              </div>
              <div class="col-12" v-if="config.whatsapps_tipo === 'dourasoft'">
                <q-input
                  v-model="config.whatsapps_from_number"
                  label="Identificação do número de telefone"
                >
                </q-input>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div v-if="config.whatsapps_tipo == 'dourasoftService'">
            <div v-if="loading" class="row flex-center q-ma-none">
              <q-card-section class="text-center q-pa-none">
                <div class="text-subtitle1 q-mb-md">{{dourasoftServiceMsg}}</div>
                <q-spinner color="grey-10" size="5em"/>
              </q-card-section>
            </div>
            <div v-else-if="tryAgain" class="row flex-center q-ma-none">
              <q-card-section class="text-center q-pa-none">
                <div class="text-subtitle1 q-mb-md">{{dourasoftServiceMsg}}</div>
                <q-btn
                  flat
                  label="Tentar novamente"
                  class="bg-primary text-white"
                  @click="gerarConexaoDourasoftService"
                  style="margin-right: 22px; margin-bottom: 10px"
                >
                </q-btn>
              </q-card-section>
            </div>
            <div v-else-if="qrcode" class="row flex-center q-pa-none">
              <q-card-section class="text-center q-ma-none">
                <div class="text-subtitle1 q-mb-md">{{dourasoftServiceMsg}}</div>
                <img :src="qrcode"/>
              </q-card-section>
            </div>
            <div v-else-if="estabelecerConexao" class="row flex-center q-pa-none">
              <q-card-section class="text-center q-pa-none">
                <div class="text-subtitle1 q-mb-md">{{dourasoftServiceMsg}}</div>
                <q-btn
                  flat
                  label="Estabelecer conexão"
                  class="bg-primary text-white"
                  @click="gerarConexaoDourasoftService"
                  style="margin-right: 22px; margin-bottom: 10px"
                >
                </q-btn>
              </q-card-section>
            </div>
            <div v-else-if="conexao == 'conectado' && tipoWhatsapp == 'dourasoftService'" class="row flex-center q-pa-none">
              <q-card-section class="text-center q-ma-none q-pa-none">
                <div class="text-subtitle2">Usuário está conectado no Whatsapp!</div>
                <div class="text-subtitle2">Nome: {{ conexaoInfo.nome }} </div>
                <div class="text-subtitle2">Número: {{ conexaoInfo.numero }} </div>
                <q-avatar>
                  <img :src="conexaoInfo.profilePicUrl">
                </q-avatar>
              </q-card-section>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <div v-if="tipoWhatsapp == 'dourasoftService' && conexao === 'conectado'">
            <q-btn
              flat
              label="Desconectar"
              clickable
              @click="desconectarUsuario"
              class="bg-negative text-white"
              style="margin-right: 22px; margin-bottom: 10px"
            >
            </q-btn>
            <q-btn
              flat
              label="Salvar"
              clickable
              @click="saveEditedSettings"
              class="bg-positive text-white"
              style="margin-right: 22px; margin-bottom: 10px"
            >
            </q-btn>
          </div>
          <div v-else>
            <q-btn
              flat
              label="Salvar"
              clickable
              @click="saveEditedSettings"
              class="bg-positive text-white"
              style="margin-right: 22px; margin-bottom: 10px"
            >
            </q-btn>
          </div>
        </q-card-actions>
      </q-card>
    </q-dialog>


  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify, Dialog } from "quasar";
export default {
  name: "modal-editar-settings",

  data() {
    return {
      recommended: false,
      config: {},
      ultimaConfig: {},
      configDefault: {},
      loading: false,
      novoTipo: false,
      tryAgain: false,
      dourasoftServiceMsg: '',
      qrcode: null,
      estabelecerConexao: false,
      intervalId: null,
      maxAttempts: 13,
      attempts: 0,
      tipos: [
        {
          value: 'digisac',
          label: 'Digisac',
        },
        {
          value: 'dtnetwork',
          label: 'DT Network',
        },
        {
          value: 'botconversa',
          label: 'BotConversa',
        },
        {
          value: 'twilio',
          label: 'Twilio',
        },
        {
          value: 'dourasoft',
          label: 'DouraSoft',
        },
        {
          value: 'dourasoftService',
          label: 'DouraSoft Service',
        },
      ],
      conexaoInfo: {
        nome: '',
        numero: '',
        profilePicUrl: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      "OpenDialogEditarSettings",
      "defaultOptions",
      "conexao",
      "tipoWhatsapp",
    ]),
    tipoAtual(){
      return this.config.whatsapps_tipo;
    }
  },
  methods: {
    closeTimeDialog() {
      this.$refs.whatsappInicioRef.hide();
      this.$refs.whatsappTerminaRef.hide();
    },
    closeModal() {
      this.recommended = false;
      this.estabelecerConexao = false;
      this.config = Object.assign({}, this.ultimaConfig);
      this.qrcode = null;
      this.$store.commit("OpenDialogEditarSettings", false);
      clearInterval(this.intervalId);
    },
    setDefaultOptions() {
      const token = this.config.whatsapps_token;

      this.config = Object.assign({}, this.configDefault);
      this.config.whatsapps_token = token;
    },
    saveEditedSettings() {
      let url = "/whatsapp/config";

      axios.post(url, this.config)
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: res.data.message,
              color: "red",
              position: "top-right"
            });
            return;
          }

          Notify.create({
            message: res.data.message,
            color: "green",
            position: "top-right"
          });
          this.getConfig();
          this.closeModal();
        })
        .catch(err => {
          if (!res.success) {
            let mensagem = "Houve um erro ao salvar as configurações, tente novamente!";

            Notify.create({
              message: mensagem,
              color: "red",
              position: "top-right"
            });
            return;
          }
        });
    },
    gerarConexaoDourasoftService(MSG = ''){
      this.tryAgain = false;
      this.estabelecerConexao = false;
      if(this.config.whatsapps_tipo == "dourasoftService"){
        let url = "/whatsapp/status?get-qrcode=1";
        this.loading = true;
        this.dourasoftServiceMsg = MSG;
        if(  typeof(MSG) != String ){
          this.dourasoftServiceMsg = 'Gerando o QRcode para o Whatsapp, isso pode demorar um pouco...';
        }
        axios.get(url)
        .then(res => {
          let data = res.data;
          if(data.success && data.qrcode && data.qrcode.base64){
            this.decodeImage(data.qrcode.base64);
          }

          if(data.success && data.qrcode && data.qrcode.instance && data.qrcode.instance.state == 'open'){
            this.decodeImage(data.qrcode.base64);
          }

          if (!data.success) {
            Notify.create({
              message: res.message,
              color: "red",
              position: "top-right"
            });

            this.qrcode = null;
            this.dourasoftServiceMsg = 'Houve um erro, deseja tentar novamente?';
            this.tryAgain = true;
          }

          this.loading = false;
        })
        .catch(err => {
          if (err) {
            Notify.create({
              message: err,
              color: "red",
              position: "top-right"
            });
          }
          this.dourasoftServiceMsg = 'Houve um erro, deseja tentar novamente?';
          this.tryAgain = true;
          this.loading = false;
        });
        return;
      }
    },
    decodeImage(qr){
      const base64Image = qr.split(",")[1];
      const binaryString = Buffer.from(base64Image, 'base64').toString("binary");
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: "image/png" });
      this.qrcode = URL.createObjectURL(blob);
      this.dourasoftServiceMsg = 'Leia este QRcode com o seu Whatsapp:';
    },
    getConfig() {
      axios.get("/whatsapp/config")
        .then(res => {
          if (!res.data.success) {
            Notify.create({
              message: "Não foi possível recuperar as configurações, ou você não possui permissão!",
              color: "red",
              position: "top-right"
            });
            return;
          }
          this.$store.commit("tipoWhatsapp", res.data.atual.whatsapps_tipo);

          if (res.data.status
          && res.data.status.responseObject
          && res.data.status.responseObject.connectionStatus
          && res.data.status.responseObject.connectionStatus == 'open'
          ) {
            this.$store.commit("conexao", 'conectado');
            this.conexaoInfo.nome = res.data.status.responseObject.profileName;
            this.conexaoInfo.numero = res.data.status.responseObject.ownerJid.split('@')[0];
            this.conexaoInfo.profilePicUrl = res.data.status.responseObject.profilePicUrl;
          }

          this.config = Object.assign({}, res.data.atual);
          this.ultimaConfig = Object.assign({}, res.data.atual);
          this.configDefault = res.data.default;
        })
        .catch(err => {
          Notify.create({
            message: "Não foi possível recuperar as configurações, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    desconectarUsuario(){
      let url = "/whatsapp/status?delete-conexao=1";
      axios.delete(url)
      .then(res =>{
        if (res.data.success == false) {
          Notify.create({
            message: res.data.message,
            color: "red",
            position: "top-right"
          });
          return;
        }

        this.dourasoftServiceMsg = res.data.message;
        this.$store.commit("conexao", '');

        this.conexaoInfo = {
          nome: '',
          numero: '',
          profilePicUrl: '',
        };

        if(this.config.whatsapps_tipo === 'dourasoftService'){
          this.estabelecerConexao = true;
        }

        Notify.create({
          message: res.data.message,
          color: "green",
          position: "top-right"
        });
      })
      .catch(err =>{
        if (err) {
          Notify.create({
            message: err,
            color: "red",
            position: "top-right"
          });
        }
      });
    }
  },
  mounted() {
    this.getConfig();
  },
  watch: {
    recommended(val) {
      if (val) {
        this.setDefaultOptions();
      }
    },
    tipoAtual(valorNovo, valorAntigo){
      if(valorNovo === 'dourasoftService' && valorAntigo != undefined && (this.conexao !== "conectado" || this.tipoWhatsapp !== 'dourasoftService')){
        this.estabelecerConexao = true;
        this.dourasoftServiceMsg = 'Deseja estabelecer uma conexão?';
      }
      if(valorNovo !== 'dourasoftService' && valorAntigo == 'dourasoftService' && this.conexao === "conectado" && this.tipoWhatsapp === "dourasoftService"){
        Dialog.create({
        title: "Você será desconectado do Whatsapp",
        message: "Tem certeza que deseja fazer isto?",
        cancel: "Cancelar",
        persistent: true
        })
        .onOk(() => {
          this.desconectarUsuario();
        })
        .onCancel(() => {
          this.config.whatsapps_tipo = 'dourasoftService';
        });
      }
    },
    OpenDialogEditarSettings(valorNovo){
      if(valorNovo && this.tipoAtual === 'dourasoftService' && this.conexao !== "conectado"){
        this.estabelecerConexao = true;
        this.dourasoftServiceMsg = 'Deseja estabelecer uma conexão?';
      }
      if(valorNovo && this.tipoAtual === 'dourasoftService' && this.conexao === "conectado" && this.tipoWhatsapp === "dourasoftService"){
        this.dourasoftServiceMsg = 'Usuário está conectado no Whatsapp!';
      }
    },
    qrcode(valorNovo, valorAntigo){
      if(valorNovo != valorAntigo && this.tryAgain != true) {
        this.intervalId = setInterval(this.verificarQRCODE, 2000);
      }
    },
  }
};
</script>
