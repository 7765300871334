<template>
  <div id="cardContasPagarVencidas" v-if="fetchUser && fetchUser.db.contasPagarVencidas">
    <q-card-section class="q-pb-sm">
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            A Pagar | Vencidas
            <div class="text-bold text-grey-9" style="font-size: 22px;" v-if="fetchUser.db.contasPagarVencidas.total != 0">
              R$ {{ fetchUser.db.contasPagarVencidas.total | currency }}
            </div>
          </div>
        </div>

        <div class="col-auto">
          <router-link class="text-grey-7" :to="urlDespesas">
            <q-icon name="fa fa-external-link" size="18px" />
          </router-link>

          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section class="q-py-none">
      <q-markup-table dense flat separator="none">
        <tbody>
          <tr
            v-for="despesa in despesas"
            :key="despesa.id"
            class="cursor-pointer"
            @click.stop="detalhar(despesa)"
          >
            <td class="text-left">{{ new Date(despesa.vencimento) | formatDate }}</td>
            <td>
              <span v-if="despesa.cadastro">
                <span v-if="despesa.is_despesas_recorrencia" >
                  <q-icon name="fa fa-repeat" size="18px" color="grey" />
                  <q-tooltip>Recorrente</q-tooltip>
                </span>
                {{ getNomeFornecedor(despesa.cadastro.nome) }}
                <q-tooltip v-if="despesa.cadastro.nome.length > 27">
                  {{ despesa.cadastro.nome }}
                </q-tooltip>
              </span>
            </td>
            <td class="text-right">R$ {{ despesa.valor | currency }}</td>
          </tr>
          <tr v-if="despesas.length === 0">
            <td colspan="4">Não há nenhuma despesa para esta semana!</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card-section>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "card-contas-pagar-vencidas",
  data() {
    return {};
  },
  methods: {
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "14",
      });
    },
    getNomeFornecedor(nome) {
      if (nome.length <= 27) {
        return nome;
      }

      return nome.substr(0, 24) + '...';
    },
    detalhar(movconta) {
      this.$store.commit("despesaQrCode", movconta.id);
      this.$store.dispatch("detalharDespesas", movconta);
    },
  },
  computed: {
    ...mapGetters(["fetchUser"]),
    despesas() {
      return this.fetchUser.db.contasPagarVencidas.despesas;
    },
    urlDespesas() {
      const { dtini, dtfim } = this.fetchUser.db.contasPagarVencidas;
      const formatedDtini = moment(dtini).format("DD-MM-YYYY");
      const formatedDtfim = moment(dtfim).format("DD-MM-YYYY");

      return `/despesas?dtini=${formatedDtini}&dtfim=${formatedDtfim}`;
    },
  },
};
</script>

<style lang="stylus" scoped>
  #cardContasPagarVencidas
    height:100%;
    overflow-x: auto;

  a
    text-decoration: none;
</style>
