<template>
  <div id="cardGrafDespesaCategoria">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            {{ fetchUser.db.despesasCategoria.title }}
          </div>
        </div>


        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section align="center" v-if="chartData.series && chartData.series.length === 0">Não há
      dados.</q-card-section>

    <q-card-section>
      <PieChart :series="chartData" :options="chartOptions" :height="320" />
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PieChart from "../Graficos/pie-chart.vue";
export default {
  name: "card-graf-despesa-categoria",
  components: {
    PieChart
  },
  data() { },
  methods: {
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "7",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser", "layout"]),
    chartData() {
      return this.fetchUser.db.despesasCategoria.series;
    },
    chartOptions() {
      return {
        labels: this.fetchUser.db.despesasCategoria.labels
      };
    }
  }
};
</script>