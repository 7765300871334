<template>
  <div id="cardGrafEmail">
    <q-card-section>
      <div class="row items-center no-wrap">
        <div class="col">
          <div class="text-grey-9" style="font-size: 16px;">
            <router-link to="/historicoemails" style="text-decoration: none; color: black">
              Histórico de e-mails
            </router-link>
          </div>
        </div>

        <div class="col-auto">
          <q-btn color="grey-7" round flat icon="more_vert">
            <q-menu cover auto-close>
              <q-list>
                <q-item clickable @click="removerCard">
                  <q-item-section>
                    <div class="text">
                      <q-icon name="delete" /> Remover
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section class="absolute-center" v-if="chartData && chartData.length === 1">{{ fetchUser.db.emailsCount.title
      }}</q-card-section>
    <q-card-section v-else>
      <PieChart :series="fetchUser.db.emailsCount.series" :options="fetchUser.db.emailsCount.options" :height="250" />
    </q-card-section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PieChart from "../Graficos/pie-chart.vue";
export default {
  name: "card-graf-email",
  components: {
    PieChart
  },
  data() { },
  methods: {
    removerCard() {
      this.$store.dispatch("trocaVisibilidadeCard", {
        cardId: "8",
      });
    },
  },
  computed: {
    ...mapGetters(["fetchUser", "layout"]),
  }
};
</script>