<template>
  <div ref="chartContainer">
    <apexchart 
      ref="chart" 
      type="bar" 
      :height="height" 
      :series="series" 
      :options="chartOptions" 
      :width="width"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: "StackedBarChart",
  components: { apexchart: VueApexCharts },
  props: {
    series: { type: Array, required: true },
    height: { type: [String, Number], default: 350 },
    width: { type: [String, Number], default: null },
    options: { type: Object, default: () => ({}) },
    isCurrency: { type: Boolean, default: false },
  },
  data() {
    return { chartOptions: this.mergeChartOptions() };
  },
  methods: {
    mergeChartOptions() {
      const defaultOptions = {
        chart: {
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '90%',
            columnWidth: '90%',
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        tooltip: {
          y: {
            formatter: function (val) {
              const formatter = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              });
              return formatter.format(val);
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          offsetX: 40,
        },
        xaxis: {
          type: 'category',
          categories: [
            "Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez",
          ],
        },
      };

      if (this.isCurrency) {
        defaultOptions.yaxis = {
          labels: {
            formatter: function (value) {
              const formatter = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              });
              return formatter.format(value);
            },
          },
        };
      }

      return Object.assign({}, defaultOptions, this.options);
    }
  }
};
</script>
