<template>
  <div ref="chartContainer">
    <apexchart ref="chart" type="donut" :height="height" :series="series" :options="chartOptions" :width="width">
    </apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: "PieChart",
  components: { apexchart: VueApexCharts },
  props: {
    series: { type: Array, required: true },
    height: { type: [String, Number], default: 350 },
    width: { type: [String, Number], default: null },
    options: { type: Object, default: () => ({}) }
  },
  data() {
    return { chartOptions: this.mergeChartOptions() };
  },
  methods: {
    mergeChartOptions() {
      const defaultOptions = {
        chart: {
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '60%',
            },
          },
        },
        legend: {
          position: 'bottom',
        },
      };

      return Object.assign({}, defaultOptions, this.options);
    },
  },
};
</script>
